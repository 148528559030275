import axios from 'axios';
import { Component } from 'react';
import jwt_decode from "jwt-decode";
const qs = require('query-string');

axios.interceptors.request.use(
  config => {
    if (localStorage.getItem('user')) {

      const jwt = JSON.parse(localStorage.getItem('user')).jwt;
      config.headers['Authorization'] = jwt;
    }

    return config;
  },
  error => {
    Promise.reject(error)
  });

axios.interceptors.response.use((response) => {
  return response
},
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      const user = JSON.parse(localStorage.getItem('user'));
      originalRequest._retry = true;
      const ID_URL = process.env.REACT_APP_ID.split('?')[0];
      return axios.post(`${ID_URL}api/auth/refresh-token`, {}, {
        headers: { Authorization: user.jwt }
      })
        .then(response => response.data)
        .then(data => data.data)
        .then(data => {
          axios.defaults.headers.common['Authorization'] = data.token;
          const token = jwt_decode(data.token);

          if (!token.roles.defaults.roles.includes('POSMO_DATAPROFILER')) {
            window.location.href = process.env.REACT_APP_ID;
          }

          localStorage.setItem('user', JSON.stringify({
            user_id: token.sub,
            roles: token.roles,
            exp: token.exp,
            jwt: data.token,
          }))

          return axios(originalRequest);
        })
        .catch(err => {
          localStorage.removeItem('user');
          window.location.href = process.env.REACT_APP_ID;
        })
    }
  });

export default function Authentication(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);

      const user = localStorage.getItem('user');

      if (!user) {
        const search = qs.parse(window.location.search);
        if (!search.t) {
          window.location.href = process.env.REACT_APP_ID
        } else {

          try {
            const token = jwt_decode(search.t);

            if (!token.roles.defaults.roles.includes('POSMO_DATAPROFILER')) {
              window.location.href = process.env.REACT_APP_ID
            }

            if (token.exp < new Date().getTime()) {
              window.location.href = process.env.REACT_APP_ID
            } else {

              localStorage.setItem('user', JSON.stringify({
                user_id: token.sub,
                roles: token.roles,
                exp: token.exp,
                jwt: search.t,
              }))

              axios.defaults.headers.common['Authorization'] = search.t;
              window.location.href = window.location.href.split("?")[0];
            }

          } catch (err) {
            window.location.href = process.env.REACT_APP_ID
          }
        }
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}