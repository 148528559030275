import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: 16,
  },
  divider: {
    margin: '16px 0',
    backgroundColor: '#444',
    padding: 0,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'left',
    margin: 0,
    color: '#ccc'
  },
  layer: {
    fontSize: 12,
    textAlign: 'left',
    marginTop: 16,
    color: '#999',
    fontWeight: 300,
  },
  h3ToUicRefBox: {
    display: 'flex',
    backgroundColor: '#333',
    color: '#aaa',
    width: 210,
    height: 25,
    fontSize: 10,
    textAlign: 'left',
  },
  infoText: {
    fontSize: 12,
    marginBottom: 6,
    textAlign: 'left',
    color: '#aaa'
  },
  button: {
    width: 95,
    height: 25,
    border: 'none',
    textTransform: 'none',
    alignContent: 'left',
    display: 'flex',
    borderRadius: 0,
    backgroundColor: '#556075',
  }
}));

export default function H3sActions(props) {
  const classes = useStyles();
  const [ newUicRef, setNewUicRef ] = React.useState('');

  const list = (features) => {
    const mapped = features
      .map(feature => {
        return <div key={`${feature.properties.h3_12}_${feature.properties.uic_ref}`} className={classes.h3ToUicRefBox}>
          <div style={{ marginRight: 8, width: 2, height: 25, backgroundColor: '#48924a'}}></div>
          <div style={{ position: 'relative', left: 0, top: -2, width: '100%'}}>
            {feature.properties.h3_12}<ChevronRightIcon style={{ fontSize: 18, position: 'relative', top: 5, }} />{feature.properties.uic_ref}<span><IconButton onClick={() => props.deleteH3Mapping(feature.properties)} style={{position: 'absolute', right: -2, top: -5,}}><DeleteIcon style={{ cursor: 'pointer', fontSize: 16, color: '#808080'}} /></IconButton></span>
          </div>
        </div>
      })

      return mapped.length ? mapped : <span style={{ fontSize: 14, }}>No Mappings</span>
  }

  return <Box
    className={classes.root}
  >
    <div>
      <h5 className={classes.subtitle}>Layers</h5>
      <div className={classes.layer}>
        <BlurCircularIcon style={{ color: '#48924a', position: 'relative', top: 4,fontSize: 16, marginRight: 4 }}/><span>h3</span><ChevronRightIcon style={{ position: 'relative', top: 4, fontSize: 16, }}/><span>{props.selectedFeature ? props.selectedFeature.properties.h3_12 : 'Select Object on a Map...'}</span>
      </div>
    </div>
    <Divider className={classes.divider} variant="fullWidth" />
    <div style={{ maxHeight: 230, overflow: 'auto' }}>
    { props.selectedFeature && list(props.data.features ? props.data.features.filter(feature => { return feature.properties.h3_12 === ( props.selectedFeature && props.selectedFeature.properties && props.selectedFeature.properties.h3_12) }) : []) }
    </div>
    { 
      props.selectedFeature ? <div style={{ marginTop: 32, }}><div className={classes.infoText}>Add New Mapping</div>
      <div className={classes.h3ToUicRefBox}>
          <div style={{ marginRight: 8, width: 2, height: 25, backgroundColor: '#48924a'}}></div>
          <div style={{ position: 'relative', left: 0, top: -2, width: '100%'}}>
            {props.selectedFeature.properties.h3_12}<ChevronRightIcon style={{ fontSize: 18, position: 'relative', top: 5, }} /><span><input onChange={e => setNewUicRef(e.target.value)} type="text" value={newUicRef} style={{ fontSize: 11, backgroundColor: '#333', color: '#999', outline: 'none', border: '1px solid #444', boxShadow: 'none', display: 'inline', width: 90, height: 14, }} /></span>
          </div>
      </div>
      <div style={{visibility: newUicRef.length && !isNaN(newUicRef) && !(newUicRef.length !== 7) ? 'visible' : 'hidden', display: 'block', marginTop: 16}}>
        <Button
          onClick={() => props.addH3Mapping(props.selectedFeature.properties.h3_12, newUicRef)}
          variant="contained"
          color="primary"
          classes={{ root: classes.button }}
          startIcon={<AddIcon />}
          disabled={!newUicRef.length && !isNaN(newUicRef) && !(newUicRef.length !== 7)}
        >Add</Button>
      </div>
    </div>
      : ''
    }
  </Box>
}