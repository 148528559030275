import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import BuildIcon from '@material-ui/icons/Build';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FlagIcon from '@material-ui/icons/Flag';
import ReplayIcon from '@material-ui/icons/Replay';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import Button from '@material-ui/core/Button';
import { getOSMRelationsGeoArea, updateRouteList, getRouteList, updateRoute } from '../api';
import CircularIndeterminate from './CircularIndeterminate';

const getTransportModeId = (transport_mode) => {
    switch (transport_mode) {
        case 'tram':
            return 5;
        case 'bus':
        case 'trolleybus':
            return 6;
        case 'train':
            return 7;
        case 'ferry':
            return 8;

        default:
            return null;
    }
}

export default class PublicTransportBuilder extends Component {

    constructor(props) {
        super(props);
        this.state = { city: '', filterByRouteStatus: 'all', filterByRouteName: '', filterByTransportMode: '', generateRoutesDisabled: false, routes: [], isLoadingRoutes: true }
    }

    async componentDidMount() {
        this.props.onUpdateNotification('Loading routes from database');
        const routes = (await getRouteList()).data;
        this.setState({
            ...this.state,
            isLoadingRoutes: false,
            routes,
        })
        this.props.onUpdateNotification(null);

    }

    handleLockClick = async (route) => {
        const locked = !route.locked;
        await updateRoute({ ...route, locked, });
        const newRoutes = this.state.routes.map(r => {
            if (parseInt(r.osm_id) === parseInt(route.osm_id)) {
                return { ...r, locked };
            }
            return { ...r };
        })
        this.setState({
            ...this.state,
            routes: newRoutes,
        })
    }

    handleFlagClick = async (route) => {
        const flagged = !route.flagged;
        await updateRoute({ ...route, flagged, });
        const newRoutes = this.state.routes.map(r => {
            if (parseInt(r.osm_id) === parseInt(route.osm_id)) {
                return { ...r, flagged };
            }
            return { ...r };
        })
        this.setState({
            ...this.state,
            routes: newRoutes,
        })
    }

    handleGenerateRoutes = async () => {

        const geoArea = !!this.state.city ? this.state.city : 'Zürich';

        this.setState({
            generateRoutesDisabled: true,
            isLoadingRelations: true,
        })
        this.props.onUpdateNotification(`Generating ${geoArea} Routes`);
        const geojson = await getOSMRelationsGeoArea(geoArea);
        this.props.onUpdateNotification(null);
        const routes = geojson.features.map(feature => {
            return {
                osm_id: feature.id.split('/')[1],
                transport_mode: feature.properties.route,
                gtfs_trips: feature.properties.ref_trips,
                gtfs_route_id: feature.properties['gtfs:route_id'],
                gtfs_trip_id_like: feature.properties['gtfs:trip_id:like'],
                name: feature.properties.name,
                ref: feature.properties.ref,
                operator: feature.properties.operator,
                network: feature.properties.network,
                gtfs_feed: feature.properties['gtfs:feed'],
                from: feature.properties.from,
                to: feature.properties.to,
                type: feature.properties.type,
                nat_ref: feature.properties.nat_ref,
                note: feature.properties.note,
                city: geoArea,
                transport_mode_id: getTransportModeId(feature.properties.route),
                flagged: false,
            }
        })
        this.props.onUpdateNotification('Storing found routes in Database');
        const newRoutes = (await updateRouteList(routes)).data;
        this.props.onUpdateNotification(null);

        this.setState({
            ...this.state,
            generateRoutesDisabled: false,
            isLoadingRelations: false,
            routes: newRoutes,
        })
    }

    handleFilterByTransportModeChange = e => {
        this.setState({
            filterByTransportMode: e.target.value,
        })
    }

    handleFilterByRouteNameChange = e => {
        this.setState({
            filterByRouteName: e.target.value,
        })
    }

    handleFilterByStatus = status => {
        this.setState({
            filterByRouteStatus: status,
        })
    }

    listRelations = () => {
        if (!this.state.routes.length) {
            <div style={{ textAlign: 'center', marginTop: 24, color: '#999' }}>
                <div>No Routes in database</div>
            </div>
        }

        let summary = {};
        this.state.routes.forEach(route => {
            if (summary[route.city]) {
                summary[route.city].total += 1;
                if (route.locked) {
                    summary[route.city].locked += 1;
                }
                if (route.flagged) {
                    summary[route.city].flagged += 1;
                }
                if (!summary[route.city].transport_modes.includes(route.transport_mode)) {
                    summary[route.city].transport_modes.push(route.transport_mode);
                }
                if (!summary[route.city].transport_mode_ids.includes(route.transport_mode_id)) {
                    summary[route.city].transport_mode_ids.push(route.transport_mode_id);
                }
            } else {
                summary[route.city] = {
                    transport_modes: [route.transport_mode],
                    transport_mode_ids: [route.transport_mode_id],
                    locked: route.locked ? 1 : 0,
                    flagged: route.flagged ? 1 : 0,
                    total: 1,
                }
            }
        })

        const htmlArray = [
            <div className="city-header" key="city_header" style={{ display: 'flex' }}>
                <div>City</div>
                <div>TMs</div>
                <div>TM Ids</div>
                <div>Flagged</div>
                <div>Locked</div>
                <div>Total</div>
            </div>
        ];

        for (let city in summary) {
            htmlArray.push(
                <div key={city} style={{ display: 'flex' }}>
                    <div>{city}</div>
                    <div>{summary[city].transport_modes.length}</div>
                    <div>{summary[city].transport_mode_ids.length}</div>
                    <div>{summary[city].flagged}</div>
                    <div>{summary[city].locked}</div>
                    <div>{summary[city].total}</div>
                </div>
            )
        }

        return htmlArray;
    }

    listSupportedCities = () => {
        return ['Zürich', 'Winterthur', 'Zug', 'Bern', 'Basel', 'Lucerne', 'Lausanne', 'Geneve', 'Thun', 'Saint Gallen', 'Biel', 'Köniz', 'Belgrade', 'Bellinzona', 'Lugano']
            .filter(city => {
                return city.toLowerCase().startsWith(this.state.city) || city.startsWith(this.state.city);
            })
            .map(city => {
                return (
                    <div onClick={() => { this.setState({ city: city }) }} style={{ cursor: 'pointer', marginBottom: 4, paddingLeft: 4, }} key={`city_search_${city}`}>{city}</div>
                )
            })
    }

    listRoutes = () => {
        if (!this.state.routes.length) {
            return (
                <div style={{ textAlign: 'center', marginTop: 24, color: '#999' }}>
                    <div>No Routes in database</div>
                    <div>Want to generate?</div>
                    <Button disabled={this.state.generateRoutesDisabled} onClick={this.handleGenerateRoutes} style={{ marginTop: 24, textTransform: 'none', backgroundColor: 'rgb(91, 189, 95)', fontSize: 14, borderRadius: 2, }}>Generate Zurich Routes</Button>
                </div>
            )
        }

        return this.state.routes.filter(route => {
            if (this.state.filterByTransportMode && this.state.filterByTransportMode.length > 2) {

                if (route.transport_mode.includes(this.state.filterByTransportMode)) {
                    return route;
                }
                return;
            }

            return route;
        }).filter(route => {
            if (this.state.filterByRouteName && this.state.filterByRouteName.length > 2) {

                if (route.name.toLowerCase().includes(this.state.filterByRouteName.toLowerCase())) {
                    return route;
                }
                return;
            }

            return route;
        })
            .filter(route => {
                if (this.state.filterByRouteStatus) {

                    switch (this.state.filterByRouteStatus) {
                        case 'all':
                            return route;
                        case 'locked':
                            return route.locked;
                        case 'unlocked':
                            return !route.locked;
                        default:
                            return route;
                    }
                }

                return route;
            })
            .map(route => {
                return (
                    <div key={route.osm_id} className="public-transport-builder-routes">
                        <div style={{ backgroundColor: '#f9c619', width: 2, height: 24 }}></div>
                        <div style={{ position: 'relative', top: 4, left: 4, }}>{route.from} → {route.to}</div>
                        <div style={{ position: 'absolute', top: 4, right: 98, }}>{route.transport_mode}</div>

                        <div style={{ position: 'absolute', right: 73, top: 4, }}>
                            <IconButton onClick={() => this.handleFlagClick(route)} style={{ padding: 0, opacity: route.locked ? 0.4 : 1, pointerEvents: route.locked ? 'none' : 'auto', }} >
                                <FlagIcon style={{ fontSize: 16, color: route.flagged ? '#e5594f' : '#999' }} />
                            </IconButton>
                        </div>
                        <div style={{ position: 'absolute', right: 48, top: 4, }}>
                            <IconButton onClick={() => this.props.onViewRoute({ ...route })} style={{ padding: 0, opacity: !route.locked ? 0.4 : 1, pointerEvents: !route.locked ? 'none' : 'auto' }}>
                                <VisibilityIcon style={{ fontSize: 16, color: '#999' }} />
                            </IconButton>
                        </div>
                        <div style={{ position: 'absolute', right: 24, top: 4, }}>
                            <IconButton onClick={() => this.props.onEditRoute({ ...route })} style={{ padding: 0, opacity: route.locked ? 0.4 : 1, pointerEvents: route.locked ? 'none' : 'auto' }}>
                                <BuildIcon style={{ fontSize: 16, color: '#999' }} />
                            </IconButton>
                        </div>
                        <div style={{ position: 'absolute', right: 4, top: 4, }}>
                            <IconButton style={{ padding: 0, }} onClick={() => this.handleLockClick(route)}>
                                {route.locked ? <LockIcon style={{ fontSize: 16, color: '#f9c61a' }} /> : <LockOpenIcon style={{ fontSize: 16, color: '#999' }} />}
                            </IconButton>
                        </div>
                    </div >
                )
            });
    }

    render() {
        const { onClose } = this.props;

        if (this.state.isLoadingRoutes) {
            return <CircularIndeterminate />
        }

        return (
            <div>
                {this.state.moreRoutesOpened ? <div className="public-transport-moreRoutes">
                    <div className="header">
                        <div style={{ paddingLeft: 4, paddingTop: 3, }}>OSM Relations</div>
                        <div onClick={() => { this.setState({ ...this.state, moreRoutesOpened: false }) }} style={{ cursor: 'pointer', color: 'rgb(153, 153, 153)', position: 'absolute', right: 2 }}><CloseIcon /></div>
                    </div>
                    <div className="public-transport-moreRoutes-table">
                        {this.listRelations()}
                    </div>
                    <div style={{ display: 'flex', margin: '24px 16px', }}>
                        <h5 style={{ color: '#999', fontSize: 14, margin: 0, padding: 0, }}>Add Relations</h5>
                    </div>
                    <div style={{ display: 'flex', margin: 24, }}>
                        <div style={{ position: 'relative' }}>
                            <input value={this.state.city} onChange={e => this.setState({ ...this.state, city: e.target.value })} style={{ outline: 'none', border: '1px solid #1f1f1f', padding: '5px 8px', backgroundColor: '#1a1919', color: '#999', fontSize: 12, }} type="text" placeholder="enter city name..." />
                            {!!this.state.city ? <div style={{ color: '#999', fontSize: 12, textAlign: 'left', position: 'absolute', backgroundColor: 'rgb(26, 25, 25)', top: 35, left: 2, width: 277, paddingTop: 4, overflow: 'auto', }}>{this.listSupportedCities()}</div> : ''}
                        </div>
                        <Button style={{ position: 'relative', top: 0 }} className="button" onClick={() => this.handleGenerateRoutes()} disabled={this.state.isLoadingRelations || !!!this.state.city ? true : false}>Load Relations</Button>
                        {this.state.isLoadingRelations ? <div style={{ marginLeft: 20, color: '#999', fontSize: 14, }}>Loading new Relations for {this.state.city} ... <br />Please be patient.<CircularIndeterminate style={{ position: 'relative', }} /></div> : ''}
                    </div>
                </div> : ''}
                <div className="public-transport-builder">
                    <div className="header">
                        <div style={{ paddingLeft: 4, paddingTop: 3, }}>Public Transport Network Builder</div>
                        <div onClick={() => onClose()} style={{ cursor: 'pointer', color: 'rgb(153, 153, 153)', position: 'absolute', right: 2 }}><CloseIcon /></div>
                    </div>
                    <div>
                        <h5 style={{ padding: '12px 8px 0 8px', color: '#ccc', margin: 0, fontSize: 16, textAlign: 'left', fontWeight: 600 }}>Routes</h5>
                    </div>
                    <div style={{ display: 'flex', margin: '4px 8px' }}>
                        <div><input value={this.state.filterByRouteName} onChange={this.handleFilterByRouteNameChange} style={{ color: '#999', fontSize: 12, outline: 'none', border: 'none', backgroundColor: '#1f1f1f', padding: '4px 8px', width: 140, marginRight: 12, }} placeholder='Filter by route name...' /></div>
                        <div><input value={this.state.filterByTransportMode} onChange={this.handleFilterByTransportModeChange} style={{ color: '#999', fontSize: 12, outline: 'none', border: 'none', backgroundColor: '#1f1f1f', padding: '4px 8px', width: 140, }} placeholder='Filter by transport mode...' /></div>
                        <div>
                            <div className="route-status">
                                <input onClick={(() => this.handleFilterByStatus('all'))} type="radio" id="route_status_all"
                                    name="route_status" value="All" />
                                <label htmlFor="route_status_all">All ({this.state.routes.length})</label>

                                <input onClick={(() => this.handleFilterByStatus('locked'))} type="radio" id="route_status_locked"
                                    name="route_status" value="Locked" />
                                <label htmlFor="route_status_locked">Locked ({this.state.routes.filter(route => { return route.locked; }).length})</label>

                                <input onClick={(() => this.handleFilterByStatus('unlocked'))} type="radio" id="route_status_unlocked"
                                    name="route_status" value="Unlocked" />
                                <label htmlFor="route_status_unlocked">Unlocked ({this.state.routes.filter(route => { return !route.locked; }).length})</label>
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: '12px 8px', overflow: 'scroll', height: 278, }}>
                        {
                            this.listRoutes()
                        }
                    </div>
                    <div style={{ display: 'flex', }}>
                        <Button onClick={() => { this.setState({ ...this.state, moreRoutesOpened: true }) }} className="button">Add more routes...</Button>
                    </div>
                </div >
            </div >
        )
    }
}