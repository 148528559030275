import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LayersIcon from '@material-ui/icons/Layers';
import Link from '@material-ui/core/Link';
import BugReportIcon from '@material-ui/icons/BugReport';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import Box from '@material-ui/core/Box';
import H3sActions from './H3sActions';
import NodeActions from './NodeActions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 240,
    position: 'absolute',
    zIndex: 3,
    height: '80vh',
    top: 80,
    left: 46,
    backgroundColor: '#1f2323',
    color: '#555'
  },
  logo: {
    padding: 16,
    fontSize: 14,
    color: '#3279ff',
    textAlign: 'left',
    fontWeight: '800',
    backgroundColor: '#272828',
  },
  text: {
    color: "#5a5959",
    fontWeight: '250',
    fontSize: 12,
  },
  bug: {
    color: '#5a5959',
    fontWeight: '250',
    fontSize: 12,
    position: 'absolute',
    right: 16,
    top: 22,
    cursor: 'pointer',
  },
  info: {
    color: '#5a5959',
    fontWeight: '250',
    fontSize: 12,
    position: 'absolute',
    right: 42,
    top: 22,
    cursor: 'pointer',
  },
  hide: {
    position: 'absolute',
    left: 266,
    top: '50%',
    background: '#1d1d1d',
    zIndex: 4,
    color: '#777',
    padding: 2,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  }
}));


export default function ActionMenu(props) {
  const classes = useStyles();
  const [hide, setHide] = React.useState(true);

  return <div>
    <div style={hide ? { left: 15, } : { left: 266 }} className={classes.hide} onClick={() => { setHide(!hide) }}>{!hide ? <ArrowBackIos /> : <ArrowForwardIos />}</div>
    <Box
      className={classes.root}
      style={hide ? { visibility: 'hidden' } : { visibility: 'visible' }}
    >
      <div className={classes.logo}>
        <LayersIcon style={{ position: 'relative', top: 6, }} />
        PTGE <span className={classes.text}>v1.1</span>
        <div className={classes.bug}><Link target="_blank" href="https://github.com/datamapio/public_transport_editor/issues" color="inherit">
          <BugReportIcon fontSize="small" /></Link></div>
        <div className={classes.info}><Link target="_blank" href="https://github.com/datamapio/public_transport_editor" color="inherit">
          <InfoIcon fontSize="small" /></Link></div>
      </div>
      <H3sActions addH3Mapping={(h3_12, uic_ref) => props.addH3Mapping(h3_12, uic_ref)} deleteH3Mapping={properties => props.deleteH3Mapping(properties)} data={props.data.h3s} selectedFeature={props.selectedEvents && props.selectedEvents.find(event => { return event.layer === 'h3' })} />
      <NodeActions deleteNodeMapping={stop => props.deleteNodeMapping(stop)} addNodeMapping={(node_id, stop) => props.addNodeMapping(node_id, stop)} data={props.data.public_transport_nodes} selectedFeature={props.selectedEvents && props.selectedEvents.find(event => { return event.layer === 'nodes' })} />
    </Box></div>
}