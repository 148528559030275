import React, { Component } from 'react';

export default class StationInsertPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uic_ref: props.popupData.feature && props.popupData.feature.properties.uic_ref
        }
    }

    renderData() {

        if (this.props.popupData) {

            const { feature } = this.props.popupData;

            // render following:
            // nodeno - readonly
            // name - readonly
            // uic_ref - editable
            const flex = { marginTop: 8, color: '#999', display: 'flex', justifyContent: 'space-between', alignItems: 'center' };

            return (
                <div>
                    <div style={flex}>
                        <div>marker_id</div><div>{this.props.popupData.marker_id}</div>
                    </div>
                    <div style={flex}>
                        <div>nodeno</div><div>{feature.properties.id.split('/')[1]}</div>
                    </div>
                    <div style={flex}>
                        <div>name</div><div>{feature.properties.name}</div>
                    </div>
                    <div style={flex}>
                        <div>uic_ref</div><div><input type="text" onChange={e => this.setState({ uic_ref: e.target.value })} value={this.state.uic_ref} /></div>
                    </div>
                </div>
            )
        }

        return null;
    }

    render() {

        const { evt } = this.props.popupData;

        const relationInfoCss = {
            root: {
                position: 'absolute',
                top: evt ? evt.center.y - 230 : 100,
                width: 420,
                height: 180,
                background: '#222',
                zIndex: 6,
                left: evt ? evt.center.x - 140 : 8,
                padding: '24px 8px',
                overflow: 'auto',
            },
            button: {
                width: 110,
                hieght: 25,
                backgroundColor: 'rgb(36, 86, 182)',
                color: '#ccc',
                padding: '8px 12px',
                outline: 'none',
                border: 'none',
                fontSize: 12,
                position: 'absolute',
                right: 8,
                bottom: 12,
                cursor: 'pointer',
            },
            dropdown: {
                position: 'absolute',
                left: 8,
                bottom: 12,
                height: 30,
                outline: 'none',
                background: '#222',
                border: '1px solid #333',
                color: '#aaa',
            },
        }

        return (
            <div style={relationInfoCss.root}>
                {this.renderData()}
                <button onClick={() => this.props.onInsertStation({
                    ...this.props.popupData,
                    feature: {
                        ...this.props.popupData.feature,
                        properties: {
                            ...this.props.popupData.feature.properties,
                            uic_ref: this.state.uic_ref,
                        }
                    }
                })} style={relationInfoCss.button}>Insert Station</button>
            </div>
        )
    }
}