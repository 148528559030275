import React, { Component } from 'react';
import bbox from '@turf/bbox';
import distance from '@turf/distance';
import './App.css';
import Notifications from './components/Notifications';
import Header from './components/Header';
import ActionMenu from './components/ActionMenu';
import LayersMenu from './components/LayersMenu';
import PublicTransportBuilder from './components/PublicTransportBuilder';
import PublicTransportActions from './components/PublicTransportActions';
import SelectStation from './components/SelectStation';
import MissingUicRefPopup from './components/MissingUicRefPopup';
import WrongMappings from './components/WrongMappings';
import Map from './components/Map';
import CircularIndeterminate from './components/CircularIndeterminate';
import { getTrainEdgesByRelationId, saveTrainEdges, deleteNodeMapping, addNodeMapping, getH3Indexes, deleteH3Mapping, addH3Mapping, getPublicTransportNodes, getPublicTransportEdges, getOSMRelation, getOSMNodes, getPublicTransportRoute, getStationWithinGPSRadius, routeTrain } from './api';
import withAuth from './components/Authentication';
import LoadRelation from './components/LoadRelation';
import RelationInfo from './components/RelationInfo';
import StationInsertPopup from './components/StationInsertPopup';
import StoreRouting from './components/StoreRouting';
const h3 = require("h3-js");

const DEFAULT_BBOX = '8.523243527237296,47.36581835944429,8.560045204388706,47.386190929152136';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { missingUicRefs: [], notification: null, fitBoundsOptions: { bbox: null }, data: { routing: { features: [] }, route: { features: [] }, route_nodes: { features: [] }, tmp_routing: { features: [], } }, activeLayers: { h3: false, nodes: false, edges: false }, markers: [] }
  }

  componentDidMount() {
    getH3Indexes(DEFAULT_BBOX)
      .then(data => data.data)
      .then(data => {
        this.setState({ data: { ...this.state.data, h3s: data } });
      })
      .catch(err => {
        console.log(err);
      });

    getPublicTransportNodes(DEFAULT_BBOX)
      .then(data => data.data)
      .then(data => {
        this.setState({ data: { ...this.state.data, public_transport_nodes: data } });
      })
      .catch(err => {
        console.log(err);
      });

    getPublicTransportEdges(DEFAULT_BBOX)
      .then(data => data.data)
      .then(data => {
        this.setState({ data: { ...this.state.data, public_transport_edges: data } });
      })
      .catch(err => {
        console.log(err);
      });

  }

  handleUpdateData(bbox) {
    if (this.state.activeLayers.h3) {
      getH3Indexes(bbox.join(','))
        .then(data => data.data)
        .then(data => {
          this.setState({ data: { ...this.state.data, h3s: data } });
        })
        .catch(err => {
          console.log(err);
        });
    }

    if (this.state.activeLayers.nodes) {
      getPublicTransportNodes(bbox.join(','))
        .then(data => data.data)
        .then(data => {
          this.setState({ data: { ...this.state.data, public_transport_nodes: data } });
        })
        .catch(err => {
          console.log(err);
        });
    }

    if (this.state.activeLayers.edges) {
      getPublicTransportEdges(bbox.join(','))
        .then(data => data.data)
        .then(data => {
          this.setState({ data: { ...this.state.data, public_transport_edges: data } });
        })
        .catch(err => {
          console.log(err);
        });
    }

  }

  handleDeleteH3Mapping(properties) {
    const result = deleteH3Mapping(properties.id);
    if (result) {
      this.setState({
        data: {
          ...this.state.data,
          h3s: {
            type: 'FeatureCollection',
            features: this.state.data.h3s.features.filter(feature => { return feature.properties.id !== properties.id })
          }
        }
      })
    }
  }

  handleAddH3Mapping(h3_12, uic_ref) {
    addH3Mapping(h3_12, uic_ref)
      .then(data => data.data)
      .then(data => {
        if (data) {
          this.setState({
            data: {
              ...this.state.data,
              h3s: {
                type: 'FeatureCollection',
                features: [...this.state.data.h3s.features, ...data]
              }
            }
          })
        }
      }).catch(() => {
        console.log(`Mapping can't be added.`);
      });
  }

  handleAddNodeMapping(node_id, stop) {

    const [stop_id, transport_mode_id] = stop.split('|');

    addNodeMapping(node_id, stop_id, parseInt(transport_mode_id))
      .then(data => data.data)
      .then(data => {
        if (data) {
          const node = this.state.data.public_transport_nodes.features.find(feature => { return feature.properties.id === node_id });
          node.properties.stops.push({
            node_id, stop_id, transport_mode_id: parseInt(transport_mode_id)
          })

          this.setState({ data: { ...this.state.data } });
        }
      }).catch((err) => {
        console.log(`Mapping can't be added.`);
      });

  }

  handleDeleteNodeMapping(stop) {
    deleteNodeMapping(stop)
      .then(data => data.data)
      .then(data => {
        if (data) {
          const node = this.state.data.public_transport_nodes.features.find(feature => { return feature.properties.id === stop.node_id });
          node.properties.stops = node.properties.stops.filter(s => { return !(s.stop_id === stop.stop_id && s.transport_mode_id === stop.transport_mode_id) })

          this.setState({ data: { ...this.state.data } });
        }
      }).catch((err) => {
        console.log(`Mapping can't be added.`);
      });
  }

  handleOpenLayersMenu = (event) => {
    this.setState({
      layersMenuOpened: true,
    })
  }

  handleLayerMenuClose = (event) => {
    this.setState({
      layersMenuOpened: false,
    })
  }

  handleSelectedEvent = (events) => {
    this.setState({
      selectedEvents: events.map(event => { return { layer: event.layer.source, properties: event.properties } })
    })
  }

  handleSetLayerActive = (layer) => {
    this.setState({
      ...this.state, activeLayers: {
        ...this.state.activeLayers,
        [layer.name]: layer.active,
      }
    })
  }

  handlePublicTransportBuilderClose = () => {
    this.setState({
      ...this.state,
      openBuilder: null,
      activeLayers: {
        ...this.state.activeLayers,
        route: false,
        route_nodes: false,
      }
    })
  }

  handleInitiatePublicTransportBuilder = () => {
    this.setState({
      ...this.state,
      openBuilder: true,
      activeLayers: {
        ...this.state.activeLayers,
        route: true,
        route_nodes: true,
      }
    })
  }

  handleUpdateNotification = (notification) => {
    this.setState({
      ...this.state,
      notification,
    })
  }

  getStationHit = (feature, features) => {
    let min = distance(feature, features[0], { units: 'meters' });
    let station = features[0];
    features.forEach(f => {
      const dist = distance(feature, f);
      if (dist < min) {
        min = dist;
        station = f;
      }
    })

    return min < 0.0001 ? station : false;
  }

  getCoordsArrayLength = (coordinateList) => {
    let length = 0;
    for (let i = 1; i < coordinateList.length; i++) {
      length += distance(coordinateList[i - 1], coordinateList[i], { units: 'meters' });
    }

    return length;
  }

  handlePublicTransportActionsClose = () => {
    this.setState({
      ...this.state,
      route_ready_for_storage: false,
      activeLayers: {
        ...this.state.activeLayers,
        route: false,
        route_nodes: false,
        routing: false,
      }
    })
  }

  handleSearch = (feature) => {

    const calculatedBBox = bbox(feature);
    this.setState({
      ...this.state,
      fitBoundsOptions: {
        bbox: [[calculatedBBox[0], calculatedBBox[1]], [calculatedBBox[2], calculatedBBox[3]]],
        padding: this.state.fitBoundsOptions.padding,
        zoom: 16,
      }
    })

  }

  handleViewRoute = async route => {

    const routeEdges = (await getPublicTransportRoute(route.osm_id)).data.geojson;
    const calculatedBBox = bbox(routeEdges);

    const routeNodes = {
      type: 'FeatureCollection',
      features: [],
    }

    routeEdges.features.forEach(edge => {
      routeNodes.features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: edge.geometry.coordinates[0],
        }
      })
    })

    routeNodes.features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: routeEdges.features[routeEdges.features.length - 1].geometry.coordinates[routeEdges.features[routeEdges.features.length - 1].geometry.coordinates.length - 1],
      }
    })


    this.setState({
      ...this.state,
      openBuilder: false,
      data: {
        ...this.state.data,
        route: routeEdges,
        route_nodes: routeNodes,
      },
      fitBoundsOptions: {
        bbox: [[calculatedBBox[0], calculatedBBox[1]], [calculatedBBox[2], calculatedBBox[3]]],
        padding: this.state.fitBoundsOptions.padding,
      },
      // we turn off edges and nodes layer in case they are on.
      activeLayers: {
        ...this.state.activeLayers,
        nodes: false,
        edges: false,
      }
    })
  }

  containsPoint = (point, coordsArray) => {
    for (let i = 0; i < coordsArray.length; i++) {
      if (Math.abs(coordsArray[i][0] - point[0]) == 0 && Math.abs(coordsArray[i][1] - point[1]) == 0) {
        return { index: i };
      }
    }
  }

  containsLoopIters = coordsArray => {
    for (let i = 0; i < coordsArray.length; i++) {
      for (let j = i + 1; j < coordsArray.length; j++) {
        if (Math.abs(coordsArray[i][0] - coordsArray[j][0]) == 0 && Math.abs(coordsArray[i][1] - coordsArray[j][1]) == 0) {
          return { i, j, length: coordsArray.length };
        }
      }
    }

    return false;
  }

  containsLoop = coordsArray => {
    for (let i = 0; i < coordsArray.length; i++) {
      for (let j = i + 1; j < coordsArray.length; j++) {
        if (Math.abs(coordsArray[i][0] - coordsArray[j][0]) == 0 && Math.abs(coordsArray[i][1] - coordsArray[j][1]) == 0) {
          return true;
        }
      }
    }

    return false;
  }

  findStationStartCoordinates = (stationStartName, stations) => {
    let startStopPositions = stations.features.filter(feature => {
      return feature.properties.uic_name === stationStartName;
    })

    if (startStopPositions.length > 1) {
      // ambiguous we need user to interact.
      return;
    }

    if (!startStopPositions.length) {
      // This means we didn't match any starting station, we need to fallback to hitting not exact station name
      startStopPositions = stations.features.filter(feature => {
        const startNameArray = stationStartName.replace(/,/g, '').split(' ').sort();
        const featureUicNameArray = feature.properties.uic_name && feature.properties.uic_name.replace(/,/g, '').split(' ').sort() || [];

        if (startNameArray.length != featureUicNameArray.length) {
          return false;
        }

        // we require that startNameArray and featureUicNameArray matches but we don't requre order.
        let match = true;

        for (let i = 0; i < startNameArray.length; i++) {
          if (startNameArray[i] !== featureUicNameArray[i]) {
            return false;
          }
        }

        return match;
      })
    }

    if (!startStopPositions.length) { return; }

    return startStopPositions[0].geometry.coordinates;
  }

  applyMerging = (multiLineString, stopPosition) => {

    // situation where we have loops inside linestring geometries then continuiation of linestring
    // we need to split this in at breaking points
    for (let i = 0; i < multiLineString.length; i++) {
      const loopIters = this.containsLoopIters(multiLineString[i]);

      if (loopIters.i + loopIters.j > loopIters.length) {
        const loopPart = multiLineString[i].slice(loopIters.i, loopIters.j + 1);
        const start = multiLineString[i].slice(0, loopIters.i + 1);
        const end = multiLineString[i].slice(loopIters.j + 1, loopIters.length);

        multiLineString.splice(i, 1, loopPart, start, end);
      }
    }

    multiLineString = multiLineString.filter(line => { return line.length; });


    const firstCoordinate = multiLineString[0][0];
    let min = distance({ type: 'Feature', geometry: { type: 'Point', coordinates: stopPosition }, properties: {} }, { type: 'Feature', geometry: { type: 'Point', coordinates: firstCoordinate }, properties: {} }, { units: 'meters' });
    let startLinestringCoordinates = firstCoordinate;
    let startLineStringIndex = 0;
    for (let i = 0; i < multiLineString.length; i++) {
      const coordinateArray = multiLineString[i];
      for (let j = 0; j < coordinateArray.length; j++) {
        const coordinates = coordinateArray[j];
        let dist = distance({ type: 'Feature', geometry: { type: 'Point', coordinates: stopPosition }, properties: {} }, { type: 'Feature', geometry: { type: 'Point', coordinates: coordinates }, properties: {} }, { units: 'meters' });
        if (dist < min) {
          min = dist;
          startLinestringCoordinates = coordinateArray[j];
          startLineStringIndex = i;
        }
      }
    }

    let connected = [];
    const contains = this.containsPoint(startLinestringCoordinates, multiLineString[startLineStringIndex]);
    console.log(contains, 'contains start coordinates lineString index');
    if (contains && contains.index > 0 && contains.index < multiLineString[startLineStringIndex].length - 1) {
      multiLineString[startLineStringIndex] = multiLineString[startLineStringIndex].slice(contains.index, multiLineString[startLineStringIndex].length)
    } else if (contains && contains.index === multiLineString[startLineStringIndex].length - 1) {
      multiLineString[startLineStringIndex] = multiLineString[startLineStringIndex].reverse();
    }
    connected.push(multiLineString[startLineStringIndex]);

    // initialize what is left to connect
    let toConnect = [];
    for (let i = 0; i < multiLineString.length; i++) {
      if (i != startLineStringIndex) {
        toConnect.push(i);
      }
    }

    console.log(connected);
    console.log(toConnect);

    while (toConnect.length != 0) {
      const lastConnected = connected[connected.length - 1];
      let iterationOk = false;
      for (let i = 0; i < toConnect.length; i++) {

        const loopExists = this.containsLoop(multiLineString[toConnect[i]]);
        const contains = this.containsPoint(lastConnected[lastConnected.length - 1], multiLineString[toConnect[i]]);
        if (contains && contains.index === 0 && !loopExists) {
          // push to connected array.
          connected.push(multiLineString[toConnect[i]]);
          toConnect.splice(i, 1);
          iterationOk = true;
        } else if (contains && contains.index === multiLineString[toConnect[i]].length - 1) {
          // filp
          connected.push(multiLineString[toConnect[i]].reverse());
          toConnect.splice(i, 1);
          iterationOk = true;
        } else if (contains && loopExists) {
          // we have a loop on this part of geometry.

          // find index in remaning geometries (there must be a connection point)
          let connection;
          let nextLineIndex;
          multiLineString[toConnect[i]].forEach(pointInLoop => {
            for (let j = 0; j < toConnect.length; j++) {
              if (i != j) {
                const loopConnection = this.containsPoint(pointInLoop, multiLineString[toConnect[j]]);
                if (loopConnection) {
                  connection = loopConnection.index;
                  nextLineIndex = j;
                }
              }
            }
          })

          const split = this.containsPoint(multiLineString[nextLineIndex][connection], multiLineString[toConnect[i]]);

          // correct this loop: index < split.index ? (index, connection)
          //                    index >= connection ? (index, end) - (end - connection)

          const { index } = contains;
          const connectionArray = index < split.index ? multiLineString[toConnect[i]].slice(index, split.index + 1)
            : multiLineString[toConnect[i]].slice(index, multiLineString[toConnect[i]].length).concat(multiLineString[toConnect[i]].slice(0, split.index + 1));
          connected.push(connectionArray);
          console.log(connectionArray);

          toConnect.splice(i, 1);
          iterationOk = true;
        }
      }

      if (!iterationOk) {
        // means there is no way to connect route to previous geometry, so there is danger of infinite loop
        // we need to a) bridge this gap or b) break the loop and report error.
        // we break the loop by emptying this stack to check (means what is left can't be on the route) e.g. not connected.
        toConnect = [];
        //throw (new Error(`There is a problem with connecting geometries, there is a threat of infinite loop, so we need to skip this route.`));
      }
    }


    const flattenMultilineString = [];
    connected.forEach(line => {
      line.forEach(coordinates => {
        flattenMultilineString.push(coordinates);
      })
    })

    return flattenMultilineString;
  }

  handleEditRoute = async (route, initialStation) => {

    this.handleUpdateNotification('Fetching OSM relation');
    const geojson = await getOSMRelation(route);

    let relevantNodes = {
      type: 'FeatureCollection',
      features: geojson.features.filter(feature => {
        return feature.properties.id && feature.properties.id.split('/')[0] === 'node';
      })
    }

    this.handleUpdateNotification('Fetching OSM Station details');
    const relevantNodeDetails = await getOSMNodes(relevantNodes);

    // we replace nodes with more datails (but we are sure it's only stations now)
    relevantNodes = relevantNodeDetails;

    relevantNodes.features = relevantNodes.features.filter(feature => {
      return feature.properties.public_transport === 'stop_position';
    });

    const missingUicRefs = relevantNodes.features.filter(feature => {
      return feature.properties.public_transport === 'stop_position' && isNaN(parseInt(feature.properties.uic_ref));
    });

    const relationFeature = geojson.features.find(feature => {
      return feature.properties.id && feature.properties.id.split('/')[0] === 'relation';
    })

    if (missingUicRefs.length) {
      console.log('there are missing uics!');
      this.setState({
        missingUicRefs,
        missing_uics_relation: relationFeature,
      })

      this.handleUpdateNotification(null);
      return;
    }

    // figure out starting station coordinates, if we can't figure it out by matching name we fallback to user to choose.
    const stopPosition = this.findStationStartCoordinates(route.from, relevantNodes) || initialStation && initialStation.geometry.coordinates;
    if (!stopPosition) {
      this.setState({
        ...this.state,
        selectStartStation: { relevantNodes, route },
        openBuilder: false,
      })
      return;
    }

    console.log(relationFeature);
    console.log(relevantNodes);
    console.log(route);
    const routeEdges = {
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: relationFeature.geometry.type === 'LineString' ? relationFeature.geometry.coordinates : this.applyMerging(relationFeature.geometry.coordinates, stopPosition)
        },
        properties: relationFeature.properties,
      }]
    }

    const calculatedBBox = bbox(routeEdges);

    this.setState({
      ...this.state,
      openBuilder: false,
      selectStartStation: null,
      data: {
        ...this.state.data,
        route: routeEdges,
        route_nodes: relevantNodes,
      },
      fitBoundsOptions: {
        bbox: [[calculatedBBox[0], calculatedBBox[1]], [calculatedBBox[2], calculatedBBox[3]]],
        padding: this.state.fitBoundsOptions.padding,
      },
      // we turn off edges and nodes layer in case they are on.
      activeLayers: {
        ...this.state.activeLayers,
        nodes: false,
        edges: false,
      }
    })

    this.handleUpdateNotification('Building Station → Station structure');
    const featureCollection = { type: 'FeatureCollection', features: [] };

    let startStation = this.getStationHit({ type: 'Feature', geometry: { type: 'Point', coordinates: routeEdges.features[0].geometry.coordinates[0] }, properties: {} }, relevantNodes.features);
    let start = 0;
    for (let i = 1; i < routeEdges.features[0].geometry.coordinates.length; i++) {
      const current = routeEdges.features[0].geometry.coordinates[i];
      // getStationHit (if exists min_distance(current, stations) == 0) => station else false
      const currentStation = this.getStationHit({ type: 'Feature', geometry: { type: 'Point', coordinates: current } }, relevantNodes.features);
      if (currentStation) {
        featureCollection.features.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: routeEdges.features[0].geometry.coordinates.slice(start, i + 1),
          },
          properties: {
            fromnodeno: parseInt(startStation.properties.id.split('/')[1]),
            tonodeno: parseInt(currentStation.properties.id.split('/')[1]),
            from_uic_ref: parseInt(startStation.properties.uic_ref),
            to_uic_ref: parseInt(currentStation.properties.uic_ref),
            transport_mode_id: route.transport_mode_id,
            gtfs_feed: route.gtfs_feed,
            gtfs_route_id: route.gtfs_route_id,
            gtfs_trip_id_like: route.gtfs_trip_id_like,
            gtfs_trips: route.gtfs_trips,
            name: route.name,
            network: route.network,
            operator: route.operator,
            length_km: parseFloat((this.getCoordsArrayLength(routeEdges.features[0].geometry.coordinates.slice(start, i + 1)) / 1000.0).toFixed(3)),
            length_m: Math.ceil(this.getCoordsArrayLength(routeEdges.features[0].geometry.coordinates.slice(start, i + 1)))
          }
        })

        start = i;
        startStation = currentStation;
      }

    }

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        route_nodes: relevantNodes,
        route: featureCollection,
        relation: route,
      },
      route_ready_for_storage: true
    })

    console.log(featureCollection);
    console.log(relevantNodes);
    this.handleUpdateNotification(null);
  }

  handleRouting = (route) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        routing: route,
      },
      activeLayers: {
        ...this.state.activeLayers,
        routing: true,
      }
    })
  }

  handleH3sUpdate = async () => {
    const { route_nodes } = this.state.data;

    const h3sToAdd = [];
    route_nodes.features.forEach(feature => {
      const h3_12 = h3.geoToH3(feature.geometry.coordinates[1], feature.geometry.coordinates[0], 12)
      const uic_ref = feature.properties.uic_ref;
      const kRing = h3.kRing(h3_12, 1);

      kRing.forEach(h3Index => {
        h3sToAdd.push({ h3_12: h3Index, uic_ref, });
      })
    })

    try {
      for (let i = 0; i < h3sToAdd.length; i++) {
        await addH3Mapping(h3sToAdd[i].h3_12, h3sToAdd[i].uic_ref);
      }

    } catch (err) {
      // something went wrong.
    }

  }

  handleSelectStartStation = (route, station) => {
    this.setState({
      ...this.state,
      selectStartStation: null,
    })

    // we just call edit again, but we pass start station now.
    this.handleEditRoute(route, station);
  }


  handleLoadRelation = async (relationId) => {

    try {

      // check if we already have this relation in db.
      const existingEdges = (await getTrainEdgesByRelationId({
        relation_id: relationId
      })).data;

      if (existingEdges.length) {

        this.handleUpdateNotification(`Relation ${existingEdges[0].name} already exists. Try another one.`)
        return;
      }

      this.handleUpdateNotification('Fetching OSM relation');
      const geojson = await getOSMRelation({
        osm_id: relationId
      });

      this.handleUpdateNotification(null);

      const calculatedBBox = bbox(geojson);

      let relevantNodes = {
        type: 'FeatureCollection',
        features: geojson.features.filter(feature => {
          return feature.properties.id && feature.properties.id.split('/')[0] === 'node';
        })
      }

      this.handleUpdateNotification('Fetching OSM Station details');
      const relevantNodeDetails = await getOSMNodes(relevantNodes);

      relevantNodeDetails.features = relevantNodeDetails.features.filter(feature => {
        return feature.properties.public_transport === 'station' || feature.properties.public_transport === 'stop_position';
      }).map(node => {
        return {
          ...node, properties: {
            ...node.properties,
            color: 'orange',
            opacity: 1,
            radius: 4,
          }
        }
      });

      this.handleUpdateNotification(null);
      console.log('relevantNodeDetails');
      console.log(relevantNodeDetails);

      for (let i = 0; i < geojson.features.length; i++) {

        const feature = geojson.features[i];
        const found = relevantNodeDetails.features.find(rn => { return rn.properties.id === feature.properties.id });
        if (found) {
          feature.properties = { ...feature.properties, ...found.properties };
        }
      }

      console.log(geojson);

      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          tmp_relation: geojson,
          tmp_nodes: relevantNodeDetails,
          tmp_routing: false,
        },
        fitBoundsOptions: {
          bbox: [[calculatedBBox[0], calculatedBBox[1]], [calculatedBBox[2], calculatedBBox[3]]],
          padding: this.state.fitBoundsOptions.padding,
        },
        activeLayers: {
          ...this.state.activeLayers,
          tmp_relation: true,
          tmp_nodes: true,
          tmp_routing: false,
        }
      })
    } catch (err) {
      this.handleUpdateNotification('Error fetching geometry from OSM server, please try again.');
    }
  }

  selectContiniousLineStrings(lineStrings) {
    const okLineStrings = [];
    for (let i = 0; i < lineStrings.length; i++) {

      let isOk = false;
      for (let j = 0; j < lineStrings.length; j++) {

        if (i !== j) {
          const ls1 = lineStrings[i];
          const ls2 = lineStrings[j];

          ls1.forEach(coordinate1 => {
            ls2.forEach(coordinate2 => {
              if (distance(coordinate1, coordinate2, { units: 'meters' }) < 0.001) {
                isOk = true;
              }
            })
          })
        }
      }

      if (isOk) {
        okLineStrings.push(lineStrings[i]);
      }
    }

    return okLineStrings;
  }

  async handleBuildRoute(startStation) {

    console.log(startStation);
    const relation = this.state.data.tmp_relation.features.find(feature => { return feature.properties.id.split('/')[0] === 'relation' })
    console.log(relation);
    // sort stations
    // assumption is that each next station is further away from starting station
    // it would be very strange that this is not the case for trains (probably works for other public transport as well for most cases)
    // one counter-example would be bus makes a detour and returns via same road where next station is actually closer to start then the one bus detoured to.
    const stations = this.state.data.tmp_nodes.features.sort((a, b) => {
      return distance(a, startStation) - distance(b, startStation);
    });

    const geometries = [];
    for (let i = 1; i < stations.length; i++) {

      const start = stations[i - 1];
      const end = stations[i];

      const routed = (await routeTrain(start, end)).data;

      this.handleUpdateNotification(`routing from ${start.name} to ${end.name}`);
      geometries.push({
        type: 'Feature',
        geometry: routed.routes[0].geometry,
        properties: {
          fromnodeno: start.properties.id.split('/')[1],
          tonodeno: end.properties.id.split('/')[1],
          from_uic_ref: start.properties.uic_ref,
          to_uic_ref: end.properties.uic_ref,
          ref: relation.properties.ref.replaceAll(' ', ''),
          name: `${relation.properties.from} - ${relation.properties.to}`,
          relation_id: relation.properties.id.split('/')[1],
          operator: relation.properties.operator,
          route: relation.properties.route,
          transport_mode_id: 7,
          stroke: i % 2 === 0 ? 'red' : 'green'
        }
      })
    }

    this.handleUpdateNotification(null);
    console.log({ type: 'FeatureCollection', features: geometries });
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        tmp_routing: {
          type: 'FeatureCollection',
          features: geometries,
        }
      },
      activeLayers: {
        ...this.state.activeLayers,
        tmp_relation: true,
        tmp_routing: true,
        tmp_nodes: true,
      },
      storeRouting: true,
    });

  }

  async handleEnvokeStationPopup(evt, id) {
    // evt.center,
    // evt.lngLat

    this.handleUpdateNotification(`Fetching possible stations around ${evt.lngLat[0]}, ${evt.lngLat[1]}`);

    // fetch stations
    const geojson = await getStationWithinGPSRadius(evt.lngLat[0], evt.lngLat[1], 300);

    if (geojson.features.length) {

      geojson.features.sort((a, b) => {
        return distance(a, { type: 'Point', coordinates: evt.lngLat }) - distance(b, { type: 'Point', coordinates: evt.lngLat });
      })

      geojson.features[0].properties = {
        ...geojson.features[0].properties,
        inserted: true,
        color: 'red',
        opacity: 1,
        radius: 10,
      }

      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
        },
        openStationInsertPopup: { feature: geojson.features[0], marker_id: id, evt }
      })

    } else {
      this.handleUpdateNotification(`No stations within radius of 300 meters, try again.`);
    }

    this.handleUpdateNotification(null);
  }


  handleStartRouting = async () => {

    // if train routing is enabled and we have at least 2 markers.
    if (this.state.trainRouting && this.state.markers.length > 1) {

      // try to route via markers

      const startFeature = {
        ...this.state.markers[0].feature,
      }

      startFeature.geometry.coordinates = [this.state.markers[0].lng, this.state.markers[0].lat];

      const endFeature = {
        ...this.state.markers[this.state.markers.length - 1].feature,
      }

      endFeature.geometry.coordinates = [this.state.markers[[this.state.markers.length - 1]].lng, this.state.markers[[this.state.markers.length - 1]].lat];

      const viaFeatures = this.state.markers.slice(1, this.state.markers.length - 1).map(viaMarker => {

        const newFeature = { type: 'Feature', ...viaMarker.feature };
        newFeature.geometry.coordinates = [viaMarker.lng, viaMarker.lng];

        return newFeature;
      });

      const routed = (await routeTrain(startFeature, endFeature, viaFeatures)).data;
      if (routed.routes.length) {

        // show geometries on a map
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            tmp_routing: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: routed.routes[0].geometry,
                  properties: {},
                }
              ],
            },
            activeLayers: {
              ...this.state.activeLayers,
              tmp_relation: false,
              tmp_routing: true,
              tmp_nodes: false,
            }
          }
        })

        console.log(routed.routes[0].geometry);
      }

    }
  }

  handleRerouteSegment = async (selectedSegment, lngLat) => {

    console.log(selectedSegment, lngLat);

    const { tmp_routing } = this.state.data;
    const { tmp_nodes } = this.state.data;

    const segment = tmp_routing.features.find(feature => {
      return feature.properties.fromnodeno === selectedSegment.properties.fromnodeno
        && feature.properties.tonodeno === selectedSegment.properties.tonodeno
    });

    const start = tmp_nodes.features.find(feature => {
      return feature.properties.id.split('/')[1] === selectedSegment.properties.fromnodeno;
    })

    const end = tmp_nodes.features.find(feature => {
      return feature.properties.id.split('/')[1] === selectedSegment.properties.tonodeno;
    })

    const routed = (await routeTrain(start, end, [{ type: 'Feature', properties: {}, geometry: { type: 'Point', coordinates: lngLat } }])).data;
    if (routed.routes.length) {
      segment.geometry = routed.routes[0].geometry;
    }


    const newTmpRouting = this.state.data.tmp_routing.features.map(feature => {
      if (feature.properties.fromnodeno === segment.properties.fromnodeno
        && feature.properties.tonodeno === segment.properties.tonodeno) {
        return { ...segment, properties: { ...segment.properties, via: lngLat } };
      }

      return { ...feature };
    })

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        tmp_routing: {
          type: 'FeatureCollection',
          features: newTmpRouting,
        }
      }
    })
  }

  handleStoreRouting = async () => {

    try {

      await saveTrainEdges(this.state.data.tmp_routing);

      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          tmp_routing: null,
          tmp_nodes: null,
          tmp_relation: null,
        },
        storeRouting: false,
        activeLayers: {
          ...this.state.activeLayers,
          tmp_relation: false,
          tmp_routing: false,
          tmp_nodes: false,
        }
      })

    } catch (err) {

      console.log(err);
      this.handleUpdateNotification(`Error while storing this route, check if uic_refs, ref is missing`)
    }
  }

  handleRerouting = async (newTmpNodes, affectedRoutes) => {

    const stations = newTmpNodes;
    const geometries = [];
    let affectedRoutesIter = 0;
    const relation = this.state.data.tmp_routing.features[0];
    const { relation_id } = this.state.data.tmp_routing.features[0];

    for (let i = 1; i < stations.length; i++) {

      const start = stations[i - 1];
      const end = stations[i];

      if (affectedRoutesIter < affectedRoutes.length && start.properties.id.split('/')[1] === affectedRoutes[affectedRoutesIter].fromnodeno
        && end.properties.id.split('/')[1] === affectedRoutes[affectedRoutesIter].tonodeno) {

        affectedRoutesIter++;
        const routed = (await routeTrain(start, end)).data;

        this.handleUpdateNotification(`routing from ${start.name} to ${end.name}`);

        geometries.push({
          type: 'Feature',
          geometry: routed.routes[0].geometry,
          properties: {
            fromnodeno: start.properties.id.split('/')[1],
            tonodeno: end.properties.id.split('/')[1],
            from_uic_ref: start.properties.uic_ref,
            to_uic_ref: end.properties.uic_ref,
            ref: relation.properties.ref.replaceAll(' ', ''),
            name: `${relation.properties.from} - ${relation.properties.to}`,
            relation_id: relation.properties.id ? relation.properties.id.split('/')[1] : relation_id,
            operator: relation.properties.operator,
            route: relation.properties.route,
            transport_mode_id: 7,
            stroke: i % 2 === 0 ? 'red' : 'green'
          }
        })
      }

    }

    this.handleUpdateNotification(null);
    console.log({ type: 'FeatureCollection', features: geometries });

    // we should replace tmp_routing with new data:
    affectedRoutesIter = 0;
    const newTmpRouting = this.state.data.tmp_routing.features.map(feature => {


      if (affectedRoutesIter < geometries.length && feature.properties.fromnodeno === geometries[affectedRoutesIter].properties.fromnodeno
        && feature.properties.tonodeno === geometries[affectedRoutesIter].properties.tonodeno) {
        affectedRoutesIter++;
        return { ...geometries[affectedRoutesIter - 1] }
      }

      return { ...feature };
    })

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        tmp_routing: {
          type: 'FeatureCollection',
          features: newTmpRouting,
        },
        tmp_nodes: {
          type: 'FeatureCollection',
          features: newTmpNodes,
        }
      },
      activeLayers: {
        ...this.state.activeLayers,
        tmp_relation: true,
        tmp_routing: true,
        tmp_nodes: true,
      }
    });
  }

  handleInsertStation(params) {

    let currentMarker;
    const oldMarkers = this.state.markers.filter(marker => {
      if (marker.id === params.marker_id) {
        currentMarker = marker;
      }
      return marker.id !== params.marker_id;
    });

    this.setState({
      ...this.state,
      markers: [...oldMarkers, {
        ...currentMarker,
        feature: params.feature,
      }],
      openStationInsertPopup: null
    })

  }

  handleEnableTrainRouting(value) {
    this.setState({
      ...this.state,
      trainRouting: value,
    })
  }

  handleOnMarkerDrag(evt, id) {
    console.log(evt, id);
    let currentMarker;
    const oldMarkers = this.state.markers.filter(marker => {
      if (marker.id === id) {
        currentMarker = marker;
      }
      return id !== marker.id;
    });

    currentMarker.lng = evt.lngLat[0];
    currentMarker.lat = evt.lngLat[1];

    this.setState({
      ...this.state,
      markers: [...oldMarkers, currentMarker]
    })

  }

  handleOnMarkerDragEnd(evt, id) {
    console.log(this.state.markers);
  }

  async handleOnMarkerClick(id) {
    const marker = this.state.markers.find(m => { return m.id === id });
    await this.handleEnvokeStationPopup({ center: marker.center, lngLat: [marker.lng, marker.lat] }, marker.id);
  }

  async handleAddMarker(marker) {
    this.setState({
      ...this.state,
      markers: [...this.state.markers, marker]
    })

    // trigger finding station at this position.
    await this.handleEnvokeStationPopup({ center: marker.center, lngLat: [marker.lng, marker.lat] }, marker.id);
  }

  render() {
    if (!(this.state.data.h3s && this.state.data.public_transport_nodes && this.state.data.public_transport_edges)) {
      return <CircularIndeterminate />
    }

    return (
      <div className="App">
        {this.state.storeRouting ? <StoreRouting handleStoreRouting={() => this.handleStoreRouting()} data={this.state.data} /> : ''}
        {this.state.openStationInsertPopup ? <StationInsertPopup onInsertStation={(params) => this.handleInsertStation(params)} popupData={this.state.openStationInsertPopup} /> : ''}
        <LoadRelation startRouting={() => { this.handleStartRouting() }} enableTrainRouting={value => this.handleEnableTrainRouting(value)} />
        {this.state.activeLayers.tmp_relation ? <RelationInfo onBuildRoute={(startStation) => this.handleBuildRoute(startStation)} data={this.state.data} /> : ''}
        <WrongMappings onSearch={this.handleSearch} data={this.state.data} />
        {this.state.missingUicRefs.length ? <MissingUicRefPopup onClose={() => { this.setState({ ...this.state, missing_uics_relation: null, missingUicRefs: [] }) }} missingUicRefs={this.state.missingUicRefs} relation={this.state.missing_uics_relation} /> : ''}
        {this.state.selectStartStation ? <SelectStation data={{ ...this.state.selectStartStation }} onSelectStartStation={this.handleSelectStartStation} /> : ''}
        {this.state.route_ready_for_storage ? <PublicTransportActions onH3sUpdate={this.handleH3sUpdate} onRouting={this.handleRouting} onUpdateNotification={this.handleUpdateNotification} data={this.state.data} onClose={this.handlePublicTransportActionsClose} /> : ''}
        <Notifications notification={this.state.notification} initiatePublicTransportBuilder={this.handleInitiatePublicTransportBuilder} />
        {this.state.openBuilder ? <PublicTransportBuilder onUpdateNotification={this.handleUpdateNotification} onViewRoute={this.handleViewRoute} onEditRoute={this.handleEditRoute} onClose={this.handlePublicTransportBuilderClose} /> : ''}
        <Header activeLayers={this.state.activeLayers} openLayersMenu={event => this.handleOpenLayersMenu(event)} />
        {!(this.state.route_ready_for_storage || this.state.openBuilder) ? <ActionMenu deleteNodeMapping={stop => this.handleDeleteNodeMapping(stop)} addNodeMapping={(node_id, stop) => this.handleAddNodeMapping(node_id, stop)} addH3Mapping={(h3_12, uic_ref) => this.handleAddH3Mapping(h3_12, uic_ref)} deleteH3Mapping={properties => this.handleDeleteH3Mapping(properties)} selectedEvents={this.state.selectedEvents} data={this.state.data} /> : ''}
        <LayersMenu activeLayers={this.state.activeLayers} setLayerActive={layer => this.handleSetLayerActive(layer)} opened={this.state.layersMenuOpened} onClose={event => this.handleLayerMenuClose(event)} />
        <Map markers={this.state.markers} onMarkerClick={marker => this.handleOnMarkerClick(marker)} onAddMarker={marker => this.handleAddMarker(marker)} onMarkerDragEnd={(evt, id) => this.handleOnMarkerDragEnd(evt, id)} onMarkerDrag={(evt, id) => this.handleOnMarkerDrag(evt, id)} trainRouting={this.state.trainRouting} rerouteSegment={(selectedSegment, lngLat) => this.handleRerouteSegment(selectedSegment, lngLat)} invokeInsertStationPopup={evt => this.handleEnvokeStationPopup(evt)} fitBoundsOptions={this.state.fitBoundsOptions} activeLayers={this.state.activeLayers} onSelect={events => this.handleSelectedEvent(events)} updateAppData={bbox => this.handleUpdateData(bbox)} data={this.state.data} />
      </div>
    );
  }
}

export default withAuth(App);