import mapboxgl from "mapbox-gl";
import { WebMercatorViewport, FlyToInterpolator } from 'react-map-gl';
import React, { useState } from 'react'
import MapGL, { Marker, MapContext, NavigationControl, GeolocateControl, Source, Layer } from 'react-map-gl'
import Contains from '@turf/boolean-contains';
import BBoxPolygon from '@turf/bbox-polygon';
import TransformScale from '@turf/transform-scale';
import Distance from '@turf/distance';
import 'mapbox-gl/dist/mapbox-gl.css'
import ExtendedMapController from './ExtendedMapController';
import { v4 as uuidv4 } from 'uuid';
import { h3ToGeoBoundary, geoToH3 } from 'h3-js';
import Pin from './Pin';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const TOKEN = "pk.eyJ1IjoiZGpyYSIsImEiOiJjamZmZ2RzamYyM2JyMzNwYWc1aThzdmloIn0.tuEuIrtp3DK0ALX2J1clEw"

const edgeColor = '#FFC300';
const hexColor = '#4caf50';
const nodeColor = '#F96019';
// const otherEdgesColor = '#9bd26a' || '#84d63a';

const navControlStyle = {
  right: 10,
  top: 115
};

const geoLocateControlStyle = {
  right: 10,
  top: 225
};

function SwitchLayer() {
  const { map } = React.useContext(MapContext);
  const [mapLayer, setMapLayer] = React.useState('dark-v10');

  const handleMapLayerChange = () => {
    if (map) {
      if (mapLayer === 'dark-v10') {
        setMapLayer('satellite-v9');
        map.setStyle('mapbox://styles/mapbox/satellite-v9');
      } else {
        setMapLayer('dark-v10');
        map.setStyle('mapbox://styles/mapbox/dark-v10');
      }
    }
  }

  return (
    <div style={{ position: 'absolute', border: '2x solid #26272b', zIndex: 5, right: 10, bottom: 25, width: 80, height: 60 }} onClick={() => handleMapLayerChange()}>
      <div style={{ position: 'relative' }}>
        <img alt="switch map style" src={mapLayer === 'dark-v10' ? "assets/satellite.png" : 'assets/map.png'} width={80} height={60} />
        <div style={{ position: 'absolute', bottom: 8, left: 8, color: mapLayer === 'dark-v10' ? '#fff' : '#fff', fontSize: 14, }}>{mapLayer === 'dark-v10' ? 'Satellite' : 'Map'}</div>
      </div>
    </div>
  )
}


const Map = (props) => {
  const mapRef = React.useRef();

  const [selectedNode, setSelectedNode] = React.useState();
  const [selectedSegment, setSelectedSegment] = React.useState();

  React.useEffect(() => {
    if (props.fitBoundsOptions.bbox) {
      const { latitude, longitude, zoom } = new WebMercatorViewport(viewport).fitBounds(props.fitBoundsOptions.bbox, { padding: { top: 100, left: 320, right: 60, bottom: 100, } });
      setViewPort({
        ...viewport,
        longitude,
        latitude,
        zoom: props.fitBoundsOptions.zoom || zoom,
        transitionDuration: 150,
        transitionInterpolator: new FlyToInterpolator()
      })
    }
  }, [props.fitBoundsOptions]);

  const mapController = new ExtendedMapController();
  const [viewport, setViewPort] = useState({
    latitude: 47.3769,
    longitude: 8.5417,
    zoom: 14,
  })

  const createHexes = (data) => {
    return {
      type: 'FeatureCollection',
      features: data.features.map(feature => {
        const polygon = h3ToGeoBoundary(feature.properties.h3_12, true);
        return { type: 'Feature', properties: { ...feature.properties, color: hexColor, opacity: 0.6 }, geometry: { type: 'Polygon', coordinates: [polygon] } }
      })
    };
  }

  const createNodes = (data) => {
    return {
      type: 'FeatureCollection',
      features: data.features.map(feature => {
        return { type: 'Feature', properties: { ...feature.properties, color: nodeColor, opacity: 0.8, radius: 4 }, geometry: feature.geometry }
      })
    };
  }

  const createEdges = (data) => {
    return {
      type: 'FeatureCollection',
      features: data.features.map(feature => {
        return { type: 'Feature', properties: { ...feature.properties, color: edgeColor, opacity: 0.6, lineWidth: 1 }, geometry: feature.geometry }
      })
    };
  }

  const getActiveLayers = () => {
    const layers = [];
    for (const layer in props.activeLayers) {

      if (props.activeLayers[layer]) {
        layers.push(`${layer}-layer`);
      }
    }

    return layers;
  }

  const [selectedFeatures, setSelectedFeatures] = useState({ type: 'FeatureCollection', properties: {}, features: [] });
  const [tmpRelationSelected, setTmpRelationSelected] = useState({ type: 'FeatureCollection', properties: {}, features: [] });

  const [current_bbox, setBBox] = useState(false);

  const _onViewportChange = viewport => setViewPort({ ...viewport })

  const h3Layer = {
    id: 'h3-layer',
    type: 'fill',
    source: 'h3',
    layout: {},
    paint: {
      'fill-color': ['get', 'color'],
      'fill-opacity': ['get', 'opacity']
    }
  };

  const nodesLayer = {
    id: 'nodes-layer',
    type: 'circle',
    source: 'nodes',
    layout: {},
    paint: {
      'circle-color': ['get', 'color'],
      'circle-opacity': ['get', 'opacity'],
      'circle-radius': ['get', 'radius'],
    }
  };

  const edgesLayer = {
    id: 'edges-layer',
    type: 'line',
    source: 'edges',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': ['get', 'color'],
      'line-opacity': ['get', 'opacity'],
      'line-width': ['get', 'lineWidth'],
    },
  };

  const routeLayer = {
    id: 'route-layer',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': ['get', 'color'],
      'line-opacity': ['get', 'opacity'],
      'line-width': ['get', 'lineWidth'],
    },
  };

  const routingLayer = {
    id: 'routing-layer',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': 'red',
      'line-opacity': ['get', 'opacity'],
      'line-width': 4,
    },
  };

  const tmpRelationLayer = {
    id: 'tmp_relation-layer',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': 'yellow',
      'line-opacity': ['get', 'opacity'],
      'line-width': 4,
    },
  };

  const tmpRoutingLayer = {
    id: 'tmp_routing-layer',
    beforeId: 'tmp_nodes-layer',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#FFCE8C',
      'line-opacity': ['get', 'opacity'],
      'line-width': 4,
    },
  };

  const tmpRoutingBorderLayer = {
    id: 'tmp_routing_border-layer',
    beforeId: 'tmp_nodes-layer',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#F38F06',
      'line-opacity': ['get', 'opacity'],
      'line-width': 8,
    },
  };

  const tmpNodesLayer = {
    id: 'tmp_nodes-layer',
    type: 'circle',
    source: 'route_nodes',
    layout: {},
    paint: {
      'circle-color': ['get', 'color'],
      'circle-opacity': ['get', 'opacity'],
      'circle-radius': ['get', 'radius'],
      "circle-stroke-width": 2,
      "circle-stroke-color": '#f1f1f1',
    }
  };

  const routeNodesLayer = {
    id: 'route_nodes-layer',
    type: 'circle',
    source: 'route_nodes',
    layout: {},
    paint: {
      'circle-color': ['get', 'color'],
      'circle-opacity': ['get', 'opacity'],
      'circle-radius': ['get', 'radius'],
    }
  };


  const featureSelectedLayer = {
    id: 'feature-selected-layer',
    type: 'line',
    source: 'feature-selected',
    layout: {},
    paint: {
      'line-color': 'orange',
      'line-width': 2,
    }
  };

  const tmpRelationSelectedLayer = {
    id: 'tmp_relation-selected-layer',
    type: 'line',
    source: 'tmp_relation-selected',
    layout: {},
    paint: {
      'line-color': 'orange',
      'line-width': 8,
    }
  };

  // sensitivity of layer tap/pan
  const eventRecognizerOptions = {
    // pan: { threshold: 35 },
    // tap: { threshold: 25 },
  }

  const renderMarkers = () => {

    console.log(props.activeLayers);
    console.log(props.data);

    if (!props.trainRouting) {
      return;
    }

    return props.markers.map(marker => {
      return (
        <Marker
          key={marker.id}
          longitude={marker.lng}
          latitude={marker.lat}
          anchor="bottom"
          draggable
          // onDragStart={}
          onDrag={evt => {
            props.onMarkerDrag(evt, marker.id)
          }}
          onClick={evt => {
            props.onMarkerClick(marker.id);
          }}
          onDragEnd={evt => props.onMarkerDragEnd(evt, marker.id)}
        >
          <Pin size={10} />
        </Marker>
      )
    })
  }

  return (
    <div>
      <MapContext.Provider>
        <MapGL ref={mapRef}
          updateData={(bbox) => {
            // update data on bbox change.
            if (!current_bbox) {
              setBBox(bbox);
            } else {
              if (Contains(BBoxPolygon([...current_bbox[0], ...current_bbox[1]]), BBoxPolygon([...bbox[0], ...bbox[1]]))) {
                // is scale larger then some factor?
                // if so, also rerender data (could be we zoomed in a lot and want to clear clutter out of bbox)
                if (Contains(BBoxPolygon([...current_bbox[0], ...current_bbox[1]]), TransformScale(BBoxPolygon([...bbox[0], ...bbox[1]]), 4))) {
                  // here we reload data.
                  // console.log('should reload data! Zooom in');
                  props.updateAppData([...bbox[0], ...bbox[1]]);
                  setBBox(bbox);
                }
              } else {
                if (!Contains(TransformScale(BBoxPolygon([...current_bbox[0], ...current_bbox[1]]), 1.6), BBoxPolygon([...bbox[0], ...bbox[1]]))) {
                  // here we reload data.
                  // console.log('should reload data! Zoom out');
                  props.updateAppData([...bbox[0], ...bbox[1]]);
                  setBBox(bbox);
                }
              }
            }


          }}
          controller={mapController}
          {...viewport}
          mapboxApiAccessToken={TOKEN}
          // style = {{ position: 'absolute', width: '100%', top: 0, bottom: 0 }}
          width="100vw" height="100vh"
          mapStyle="mapbox://styles/mapbox/dark-v10"
          //mapbox://styles/mapbox/satellite-v9
          onViewportChange={_onViewportChange}
          eventRecognizerOptions={eventRecognizerOptions}
          clickRadius={0}
          touchZoom={true}
          scrollZoom={true}
          interactiveLayerIds={getActiveLayers()}
          onClick={(evt) => {

            if (props.trainRouting) {
              // in case checkbox is selected, we generate markers on each click
              props.onAddMarker({
                id: uuidv4(),
                lng: evt.lngLat[0],
                lat: evt.lngLat[1],
                center: evt.center,
              })
            }

            if (evt.features && evt.features[0] && evt.features[0].layer && evt.features[0].layer.source === 'tmp_routing') {
              // in case we click on line itself we should initialize draggable marker to help with routing
              // only update that particular segment.



              setSelectedSegment(evt.features[0]);

              return;
            }

            if (evt.features && evt.features[0] && evt.features[0].layer && evt.features[0].layer.source === 'tmp_nodes') {
              console.log(evt);

              return;
            }

            if (evt.features && evt.features[0] && evt.features[0].layer && evt.features[0].layer.source === 'tmp_relation') {

              console.log(evt);
              // evt.lngLat
              // evt.center (x, y)

              // we should envoke popup asking user to insert new station at clicked point.
              // if props.tmp_routing doesn't already exists
              if (!props.data.tmp_routing) {
                props.invokeInsertStationPopup(evt);
              }

              return;
            }



            if (evt.features[0] && evt.features[0].properties.stops) {
              evt.features[0].properties.stops = JSON.parse(evt.features[0].properties.stops);
            }

            if (evt.features[0]) {
              setSelectedFeatures({
                type: 'FeatureCollection',
                properties: {}, features: [{
                  type: 'Feature',
                  properties: evt.features[0].properties,
                  geometry: evt.features[0].geometry,
                }]
              });

              // when nodes and other layers are implemented,
              // pass other other selections of different type (but one per type)
              props.onSelect([evt.features[0]]);
            } else {
              if (props.activeLayers.h3) {
                const polygon = h3ToGeoBoundary(geoToH3(evt.lngLat[1], evt.lngLat[0], 12), true);
                const feature = { type: 'Feature', properties: { h3_12: geoToH3(evt.lngLat[1], evt.lngLat[0], 12), virtual: true, color: hexColor, opacity: 0.6 }, geometry: { type: 'Polygon', coordinates: [polygon] } };
                setSelectedFeatures({
                  type: 'FeatureCollection',
                  properties: {}, features: [feature]
                });

                props.onSelect([{ ...feature, ...{ layer: { source: 'h3' } } }]);
              }

            }
          }}
        >
          <NavigationControl style={navControlStyle} />
          <GeolocateControl
            style={geoLocateControlStyle}
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />
          {renderMarkers()}
          <Source id="tmp_routing_border" type="geojson" data={props.data.tmp_routing}>
            {props.activeLayers.tmp_routing ? <Layer {...tmpRoutingBorderLayer} /> : ''}
          </Source>
          <Source id="tmp_routing" type="geojson" data={props.data.tmp_routing}>
            {props.activeLayers.tmp_routing ? <Layer {...tmpRoutingLayer} /> : ''}
          </Source>
          <Source id="tmp_relation" type="geojson" data={props.data.tmp_relation}>
            {props.activeLayers.tmp_relation ? <Layer {...tmpRelationLayer} /> : ''}
          </Source>
          <Source id="tmp_nodes" type="geojson" data={props.data.tmp_nodes}>
            {props.activeLayers.tmp_nodes ? <Layer {...tmpNodesLayer} /> : ''}
          </Source>
          <Source id="routing" type="geojson" data={createEdges(props.data.routing)}>
            {props.activeLayers.routing ? <Layer {...routingLayer} /> : ''}
          </Source>
          <Source id="route" type="geojson" data={createEdges(props.data.route)}>
            {props.activeLayers.route ? <Layer {...routeLayer} /> : ''}
          </Source>
          <Source id="route_nodes" type="geojson" data={createNodes(props.data.route_nodes)}>
            {props.activeLayers.route_nodes ? <Layer {...routeNodesLayer} /> : ''}
          </Source>
          <Source id="h3" type="geojson" data={createHexes(props.data.h3s)}>
            {props.activeLayers.h3 ? <Layer {...h3Layer} /> : ''}
          </Source>
          <Source id="edges" type="geojson" data={createEdges(props.data.public_transport_edges)}>
            {props.activeLayers.edges ? <Layer {...edgesLayer} /> : ''}
          </Source>
          <Source id="nodes" type="geojson" data={createNodes(props.data.public_transport_nodes)}>
            {props.activeLayers.nodes ? <Layer {...nodesLayer} /> : ''}
          </Source>
          <Source id="feature-selected" type="geojson" data={selectedFeatures}>
            <Layer {...featureSelectedLayer} />
          </Source>
          <Source id="tmp_relation-selected" type="geojson" data={tmpRelationSelected}>
            <Layer {...tmpRelationSelectedLayer} />
          </Source>
        </MapGL>
        <SwitchLayer />
      </MapContext.Provider>
    </div>
  )
}

export default Map;