import { Button } from '@material-ui/core';
import React, { Component } from 'react';

export default class StartStation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSelectStation = (station) => {
        this.setState({
            selectedStation: station,
        })
    }

    renderNodes() {

        return this.props.data.relevantNodes.features.map(feature => {

            return (
                <div style={{ display: 'flex', position: 'relative', marginTop: 8, }}>
                    <div>{feature.properties.uic_name || feature.properties.name}</div>
                    <div style={{ position: 'absolute', right: 16 }}><input onClick={() => this.handleSelectStation(feature)} type="radio" radioGroup="startStation" /></div>
                </div>
            )
        })
    }

    render() {
        return (
            <div className="select-station">
                <h5>Select Start Station</h5>
                <div>
                    There is a mismatch in selecting starting station for route: {this.props.data.route.from} → {this.props.data.route.to}.
                    Please select starting station in the list below. Starting Station should have similar name to <b style={{ display: 'block', marginTop: 24, }}>{this.props.data.route.from}</b>
                </div>
                <div className="select-station-selection" style={{ overflow: 'scroll', height: 290, }}>{this.renderNodes()}</div>
                <div><Button disabled={!this.state.selectedStation} onClick={() => this.props.onSelectStartStation(this.props.data.route, this.state.selectedStation)}>Select</Button></div>
            </div>
        )
    }
}