import axios from 'axios';
const qs = require('qs');
const osmtogeojson = require('osmtogeojson');

const getGeoAreaId = (geoArea) => {
  switch (geoArea) {
    case 'zurich':
    case 'zürich':
      return 3601682248;
    case 'belgrade':
    case 'beograd':
      return 3602728438;
    case 'winterthur':
      return 3601682243;
    case 'zug':
      return 3601686447;
    case 'bern':
      return 3601682378;
    case 'basel':
      return 3601683619;
    case 'lucerne':
      return 3601682891;
    case 'lausanne':
      return 3600365554;
    case 'geneve':
      return 3601685488;
    case 'thun':
      return 3601682682;
    case 'saint gallen':
      return 3601683941;
    case 'biel':
      return 3601682380;
    case 'köniz':
      return 3601682518;
    case 'lugano':
      return 3602888417;
    case 'bellinzona':
      return 3601684625;
    case 'munich':
      return '3600062428'
    default:
      return null;
  }
}

export const getOSMNodes = async nodes => {

  const interpretableNodes = nodes.features.map(feature => {
    return `node(${feature.properties.id.split('/')[1]});`
  }).join('');

  let strReq = qs.stringify({
    data: `
        [out:json][timeout:120];
        
        // gather results
        (
          ${interpretableNodes}
        );
        // print results
        out body;
        >;
        out skel qt;
      `
  });
  const nodesResponse = await axios.post(`${process.env.REACT_APP_OVERPASS_TURBO_INTERPRETER_URL}`, strReq, {
    headers: {
      [`Content-Type`]: 'application/x-www-form-urlencoded',
    }
  });

  const { data } = nodesResponse;
  let geojson = osmtogeojson(data);

  return geojson;
}

export const getOSMRelationsGeoArea = async geoArea => {
  const geoAreaId = getGeoAreaId(geoArea.toLowerCase());
  if (!geoAreaId) {
    throw new Error(`geoAreaId for ${geoArea} is unknown, contact djra.`);
  }

  let strReq = qs.stringify({
    data: `
        [out:json][timeout:120];
        
        //{{geocodeArea:'geoArea'}}
        area(id:${geoAreaId})->.searchArea;
        // gather results
        (
          //relation[~"^gtfs:.*$"~"."](area.searchArea);
          relation["route"~"bus"](area.searchArea);
          relation["route"~"tram"](area.searchArea);
          relation["route"~"trolleybus"](area.searchArea);
          relation["route"~"ferry"](area.searchArea);
        );
        // print results
        out body;
        >;
        out skel qt;
      `
  });
  const relationResponse = await axios.post(`${process.env.REACT_APP_OVERPASS_TURBO_INTERPRETER_URL}`, strReq, {
    headers: {
      [`Content-Type`]: 'application/x-www-form-urlencoded',
    }
  });

  const { data } = relationResponse;
  let geojson = osmtogeojson(data);
  geojson.features = geojson.features.filter(feature => {
    return feature.properties.id && feature.properties.id.split('/')[0] === 'relation';
  })

  return geojson;
}

export const getOSMRelation = async relation => {

  const id = relation.osm_id;

  let strReq = qs.stringify({
    data: `
        [out:json][timeout:120];
  
        // gather results
        (
          //rel(${id})->.a;
          //(rel.a;>>;);
          rel(${id});
        );
        // print results
        out body;
        >;
        out skel qt;
      `
  });
  const relationResponse = await axios.post(`${process.env.REACT_APP_OVERPASS_TURBO_INTERPRETER_URL}`, strReq, {
    headers: {
      [`Content-Type`]: 'application/x-www-form-urlencoded',
    }
  });

  const { data } = relationResponse;
  const geojson = osmtogeojson(data);
  return geojson;
}

export const getStationWithinGPSRadius = async (lon, lat, radius) => {
  let strReq = qs.stringify({
    data: `
      [out:json][timeout:120];
  
      // gather results
      (
        node(around:${radius},${lat},${lon})["public_transport"="stop_position"];
      );
      // print results
      out body;
      >;
      out skel qt;
      `
  });

  const response = await axios.post(`${process.env.REACT_APP_OVERPASS_TURBO_INTERPRETER_URL}`, strReq, {
    headers: {
      [`Content-Type`]: 'application/x-www-form-urlencoded',
    }
  });

  const { data } = response;
  const geojson = osmtogeojson(data);
  return geojson;
}

export const routeTrain = async (startFeature, endFeature, viaFeatures) => {
  if (viaFeatures && viaFeatures.length) {
    let coords = [];

    coords.push(startFeature.geometry.coordinates.join(','));
    viaFeatures.forEach(feature => {
      coords.push(feature.geometry.coordinates.join(','))
    })
    coords.push(endFeature.geometry.coordinates.join(','));

    return axios.get(`${process.env.REACT_APP_MARS_ROUTING}/route/v1/driving/${coords.join(';')}?geometries=geojson&overview=full&alternatives=false`);
  } else {
    return axios.get(`${process.env.REACT_APP_MARS_ROUTING}/route/v1/driving/${startFeature.geometry.coordinates.join(',')};${endFeature.geometry.coordinates.join(',')}?geometries=geojson&overview=full&alternatives=false`);
  }
}

export const getPublicTransportRoute = async (osm_id) => {
  return axios.get(`${process.env.REACT_APP_API_ROUTING}/v1/getRoute/${osm_id}`);
}

export const getRouteList = async () => {
  return axios.get(`${process.env.REACT_APP_API_ROUTING}/v1/getRouteList`);
}

export const savePublicTransportNodeToStops = async data => {
  return axios.post(`${process.env.REACT_APP_API_ROUTING}/v1/savePublicTransportNodeToStops`, data);
}

export const getTrainEdgesByRelationId = async (params) => {
  return axios.get(`${process.env.REACT_APP_API_ROUTING}/v1/getTrainEdgesByRelationId?relation_id=${params.relation_id}`);
}

export const saveTrainEdges = async route => {
  return axios.post(`${process.env.REACT_APP_API_ROUTING}/v1/saveTrainEdges`, route);
}

export const savePublicTransportEdges = async route => {
  return axios.post(`${process.env.REACT_APP_API_ROUTING}/v1/savePublicTransportEdges`, route);
}

export const getRouting = async toRoute => {
  return axios.post(`${process.env.REACT_APP_API_ROUTING}/v1/getRouting`, toRoute);
}

export const savePublicTransportNodes = async route_nodes => {
  return axios.post(`${process.env.REACT_APP_API_ROUTING}/v1/savePublicTransportNodes`, route_nodes);
}

export const updateRoute = async route => {
  return axios.post(`${process.env.REACT_APP_API_ROUTING}/v1/updateRoute`, route);
}

export const updateRouteList = async routes => {
  return axios.post(`${process.env.REACT_APP_API_ROUTING}/v1/updateRouteList`, routes);
}

export const getH3Indexes = async bbox => {
  return axios.get(`${process.env.REACT_APP_API}/v1/getH3Indexes/${bbox}?user_id=${JSON.parse(localStorage.getItem('user')).user_id}`);
}

export const getPublicTransportNodes = async bbox => {
  return axios.get(`${process.env.REACT_APP_API_ROUTING}/v1/getPublicTransportNodes/${bbox}`);
}

export const getPublicTransportEdges = async bbox => {
  return axios.get(`${process.env.REACT_APP_API_ROUTING}/v1/getPublicTransportEdges/${bbox}`);
}

export const deleteH3Mapping = async id => {
  try {
    const result = await axios.post(`${process.env.REACT_APP_API}/v1/deleteH3Mapping`, { id, user_id: JSON.parse(localStorage.getItem('user')).user_id });
    return result;
  } catch {
    return;
  }
}

export const addH3Mapping = async (h3_12, uic_ref) => {
  try {
    const user_id = JSON.parse(localStorage.getItem('user')).user_id;
    const result = await axios.post(`${process.env.REACT_APP_API}/v1/addH3Mapping`, { user_id, h3_12, uic_ref });
    return result;
  } catch {
    return;
  }
}

export const addNodeMapping = async (node_id, stop_id, transport_mode_id) => {
  try {
    const result = await axios.post(`${process.env.REACT_APP_API_ROUTING}/v1/addNodeMapping`, { node_id, stop_id, transport_mode_id });
    return result;
  } catch {
    return;
  }
}

export const deleteNodeMapping = async (stop) => {
  try {
    const result = await axios.post(`${process.env.REACT_APP_API_ROUTING}/v1/deleteNodeMapping`, stop);
    return result;
  } catch {
    return;
  }
}
