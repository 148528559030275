import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

export default class StoreRouting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            codeCopied: false,
        }
    }

    renderCode() {
        if (this.props.data && this.props.data.tmp_routing) {
            return JSON.stringify(this.props.data.tmp_routing);
        }

        return '';
    }

    handleCopyCodeClicked = () => {
        navigator.clipboard.writeText(document.getElementById('tcodesnippet').value);
        this.setState({
            codeCopied: true,
        })
    }

    render() {

        const storeRoutingCss = {
            root: {
                position: 'absolute',
                top: 120,
                left: 8,
                width: 320,
                height: 300,
                zIndex: 10,
                backgroundColor: 'rgb(34, 34, 34)',
            }
        }
        return (
            <div className='public-transport-actions-codesnippet' style={storeRoutingCss.root}>
                <textarea id="tcodesnippet">{this.renderCode()}</textarea>
                <Button style={{ marginRight: 64, }} onClick={() => this.handleCopyCodeClicked()}>Copy Geojson</Button>
                <Button onClick={() => this.props.handleStoreRouting()} disabled={!this.state.codeCopied}>Store Routing</Button>
            </div>
        )
    }
}