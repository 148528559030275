import {MapController} from 'react-map-gl';
import {WebMercatorViewport} from 'react-map-gl';


  export default class ExtendedMapController extends MapController {

    constructor() {
      super();
      // subscribe to additional events
      this.events = ['panend', 'wheel'];
    }

    setOptions(options) {
      super.setOptions(options);
      this.updateData = options.updateData;
    }

    // Override the default handler in MapController
    handleEvent(event) {
      if (event.type === 'panend' || event.type === 'wheel') {
        const viewport = new WebMercatorViewport(this.getMapState().getViewportProps());
        this.updateData(viewport.getBounds());
      }
      return super.handleEvent(event);
    }
  }