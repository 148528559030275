import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import LayersIcon from '@material-ui/icons/Layers';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    color: 'rgb(31, 186, 214)'
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const getActiveLayersCount = () => {
    let i = 0;
    for (let l in props.activeLayers) {
      if (props.activeLayers[l] && !(props.activeLayers.route || props.activeLayers.route_nodes)) {
        i++;
      }
    }
    return i;
  }

  const handleLayersMenuOpen = (event) => {
    if (typeof props.openLayersMenu === 'function') {
      props.openLayersMenu(event);
    }
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow} style={{ position: 'fixed', zIndex: 4, width: '100%' }}>
      <AppBar position="static" className={classes.root}>
        <Toolbar>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              style={{ position: 'relative', right: -20, top: 40, }}
              aria-label="show number of selected layers"
              color="inherit"
              onClick={handleLayersMenuOpen}
            >
              <Badge badgeContent={getActiveLayersCount()} color="secondary">
                <LayersIcon />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
}
