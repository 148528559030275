import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

export default class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const style = {
            backgroundColor: '#272828',
            zIndex: 7,
            width: '100%',
            height: 24,
            fontSize: 12,
            textAlign: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            color: '#999',
            paddingTop: 6,
            borderBottom: '1px solid #1a1919',
        }
        return (
            <div style={style}>
                {this.props.notification ? <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>{this.props.notification}<div className="dot-flashing"></div></div> : 'Welcome to Public Transport Geo Editor'} <Button style={{ color: '#f1f1f1', position: 'absolute', right: 16, top: 5, backgroundColor: '#2456b6', height: 22, textTransform: 'none', fontSize: 10, borderRadius: 1, fontWeight: 300, }} onClick={() => this.props.initiatePublicTransportBuilder()}>Build Network...</Button>
            </div>
        )
    }
}