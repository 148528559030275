import React, { Component } from 'react';

export default class LoadRelation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            trainRouting: false,
        };
    }

    render() {

        const loadRelationCss = {
            root: {
                position: 'absolute',
                top: 57,
                padding: 0,
                zIndex: 4,
                fontSize: 12,
                left: 16,
            },
        }

        return (
            <div style={loadRelationCss.root}>
                <div style={{ borderRadius: 8, backgroundColor: '#fff', padding: '4px 8px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', }}><input type="checkbox" checked={this.state.trainRouting} onClick={e => { this.setState({ trainRouting: e.target.checked }); this.props.enableTrainRouting(e.target.checked); }} /><div>Train Routing</div></div>
                <button onClick={() => this.props.startRouting()} style={{ fontSize: 16, backgroundColor: '#fff', padding: '8px 4px', outline: 'none', borderRadius: 8, border: '1px solid #fafafa', width: 120, marginTop: 8, cursor: 'pointer', }}>Route</button>
            </div>
        )
    }
}