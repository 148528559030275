import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { geoToH3 } from 'h3-js';

export default class WrongMappings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wrongMappingsPopupOpened: false,
            unmatchedFeatures: [],
        }
    }

    componentDidUpdate(prevProps) {
        const unmatchedFeatures = [];

        if (prevProps.data.h3s.features.length != this.props.data.h3s.features.length) {
            this.props.data.public_transport_nodes.features.forEach(feature => {

                const { name, stops } = feature.properties;
                const [lon, lat] = feature.geometry.coordinates;
                const h3_index = geoToH3(lat, lon, 12);

                const unmatchedFeature = this.props.data.h3s.features.find(f => {
                    return f.properties.h3_12 === h3_index && !stops.find(stop => { return stop.stop_id.startsWith(f.properties.uic_ref) });
                });

                if (unmatchedFeature) {
                    unmatchedFeatures.push({ ...unmatchedFeature, properties: { ...unmatchedFeature.properties, name, stops } });
                }
            })

            this.setState({
                unmatchedFeatures,
            })
        }

    }

    listWrongMappings() {
        return this.state.unmatchedFeatures.map(feature => {
            const { id, name, stops, uic_ref, h3_12 } = feature.properties;
            return (
                <div key={id} style={{ display: 'flex', fontSize: 12, position: 'relative', left: 0, }}>
                    <div style={{ marginRight: 12, width: 2, height: 30, backgroundColor: 'rgb(249, 96, 25)' }}></div>
                    <div style={{ position: 'absolute', left: 12, top: 6, }}>{name}</div>
                    <div style={{ top: 6, position: 'absolute', left: 210, }}>{stops[0].stop_id}</div>
                    <div style={{ top: 6, position: 'absolute', left: 300, }}>{uic_ref}</div>
                    <div style={{ top: 6, position: 'absolute', left: 365, }}>{h3_12}</div>
                    <div onClick={() => { this.setState({ wrongMappingsPopupOpened: false, }); this.props.onSearch(feature) }} style={{ top: 6, position: 'absolute', right: 36, }}><IconButton style={{ left: 0, position: 'absolute', top: -12, color: '#999', }}><SearchIcon style={{ fontSize: 18, }} /></IconButton></div>
                </div>
            )
        })
    }

    render() {
        return (
            <div>
                <div style={{ position: 'absolute', right: 63, top: 55, zIndex: 8, width: 26, height: 26, backgroundColor: '#fff', borderRadius: 4, }}>
                    <Badge style={{ position: 'relative', left: 10, }} anchorOrigin={{ vertical: 'top', horizontal: 'right', }} badgeContent={this.state.unmatchedFeatures.length} color="secondary">
                        <WarningIcon onClick={() => { this.setState({ wrongMappingsPopupOpened: true }) }} style={{ cursor: 'pointer', fontSize: 18, position: 'relative', top: 2, left: -10, }} />
                    </Badge>
                </div>
                <div className="wrong-mappings" style={{ visibility: this.state.wrongMappingsPopupOpened ? 'visible' : 'hidden' }}>
                    <div className="header">
                        <div>Wrong mappings?</div>
                        <div onClick={() => { this.setState({ wrongMappingsPopupOpened: false }) }} style={{ cursor: 'pointer', color: 'rgb(153, 153, 153)', position: 'absolute', right: 2 }}><CloseIcon /></div>
                    </div>
                    <div className="wrong-mappings-list">
                        {this.listWrongMappings()}
                    </div>
                </div>
            </div>
        )
    }
}