import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LayersIcon from '@material-ui/icons/Layers';
import TrainIcon from '@material-ui/icons/Train';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import TramIcon from '@material-ui/icons/Tram';
import SubwayIcon from '@material-ui/icons/Subway';

const useStyles = makeStyles({
  modal: {
    backgroundColor: '#272828',
    color: '#999',
  },
  list: {
    width: 250,
    color: '#999'
  },
  fullList: {
    width: 'auto',
  },
  subheader: {
    marginLeft: 16
  }
});

export default function LayersMenu(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [h3sChecked, setH3sChecked] = React.useState(true);
  const [nodesChecked, setNodesChecked] = React.useState(true);
  const [edgesChecked, setEdgesChecked] = React.useState(true);
  const [tramChecked, setTramChecked] = React.useState(false);
  const [busChecked, setBusChecked] = React.useState(false);

  const getIcon = (name) => {
    switch (name) {
      case 'Train':
        return <TrainIcon />
      case 'Tram':
        return <TramIcon />
      case 'Bus':
        return <DirectionsBusIcon />
      case 'Subway':
        return <SubwayIcon />
      default:
        return '';
    }
  }

  React.useEffect(() => {
    setState({ right: props.opened });
  }, [props]);

  React.useEffect(() => {
    if (!props.activeLayers['tram']) {
      setTramChecked(false);
    }
    if (!props.activeLayers['bus']) {
      setBusChecked(false);
    }
    if (!props.activeLayers['h3']) {
      setH3sChecked(false);
    }
    if (!props.activeLayers['nodes']) {
      setNodesChecked(false);
    }
    if (!props.activeLayers['edges']) {
      setEdgesChecked(false);
    }
  }, [props.activeLayers])

  const handleH3sChange = (event) => {
    setH3sChecked(event.target.checked);
    props.setLayerActive({ name: 'h3', active: event.target.checked });
  }

  const handleNodesChange = (event) => {
    setNodesChecked(event.target.checked);
    props.setLayerActive({ name: 'nodes', active: event.target.checked });
  }

  const handleEdgesChange = (event) => {
    setEdgesChecked(event.target.checked);
    props.setLayerActive({ name: 'edges', active: event.target.checked });
  }

  const handleTramChange = (event) => {
    setTramChecked(event.target.checked);
    props.setLayerActive({ name: 'tram', active: event.target.checked });
  }

  const handleBusChange = (event) => {
    setBusChecked(event.target.checked);
    props.setLayerActive({ name: 'bus', active: event.target.checked });
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <h4 className={classes.subheader} >Layers</h4>
      <List>
        {['H3s', 'Nodes', 'Edges'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              <Checkbox
                size="small"
                style={{ color: '#999' }}
                disabled={index > 2 ? true : false}
                checked={index === 0 ? h3sChecked : index === 1 ? nodesChecked : index === 2 ? edgesChecked : false}
                onChange={index === 0 ? handleH3sChange : index === 1 ? handleNodesChange : index === 2 ? handleEdgesChange : () => { }}
              />
            </ListItemIcon>
            <ListItemIcon style={{ color: '#999' }}><LayersIcon /></ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['Train', 'Tram', 'Bus', 'Subway'].map((text, index) => (
          <ListItem button key={text} style={{ opacity: 0.4 }}>
            <ListItemIcon>
              <Checkbox
                size="small"
                style={{ color: '#999' }}
                disabled={true}
                checked={index === 0 ? false : index === 1 ? tramChecked : index === 2 ? busChecked : false}
                onChange={index === 1 ? handleTramChange : index === 2 ? handleBusChange : index === 3 ? handleEdgesChange : () => { }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </ListItemIcon>
            <ListItemIcon style={{ color: '#999' }}>{getIcon(text)}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      {['right'].map((anchor) => (
        <Drawer classes={{ paper: classes.modal }} key={'right'} anchor={anchor} open={state[anchor]} onClose={() => { props.onClose(); }}>
          {list(anchor)}
        </Drawer>
      ))}
    </div>
  );
}
