import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';

export default class MissingUicRefPopup extends Component {

    renderMissingUicRefs() {
        return this.props.missingUicRefs.map(station => {
            return (
                <div key={station.properties.id} style={{ display: 'flex', color: '#999', fontSize: 12, padding: '2px 8px' }}>
                    <div><a target="_blank" href={`https://www.openstreetmap.org/node/${station.id.split('/')[1]}`}>{station.properties.name}</a></div>
                </div>
            )
        })
    }
    render() {
        return (
            <div className="missingUicRefPopup">
                <div className="header">
                    <div>Missing Uic Refs</div>
                    <div onClick={() => { this.props.onClose() }} style={{ cursor: 'pointer', color: 'rgb(153, 153, 153)', position: 'absolute', right: 2 }}><CloseIcon /></div>
                </div>
                <div style={{ textAlign: 'left', fontSize: 14, color: '#999', padding: 24, }}>Sorry we can't build {this.props.relation.properties.from} → {this.props.relation.properties.to} routing.
                    There are stations which are missing uic_ref attributes.
                </div>
                <div style={{ marginLeft: 14, height: 322, overflow: 'scroll' }}>
                    <div>
                        {this.renderMissingUicRefs()}
                    </div>
                </div>
            </div>
        )
    }
}