import React, { Component } from 'react';

export default class RelationInfo extends Component {

    constructor(props) {
        super(props);

        if (this.props.data && this.props.data.tmp_relation) {
            const relation = this.props.data.tmp_relation.features.filter(feature => { return feature.properties.id.split('/')[0] === 'relation' });
            const probableStartStation = relation[0].properties.from;
            this.state = {
                startStation: probableStartStation
            }
        }
    }

    renderData() {

        if (this.props.data && this.props.data.tmp_relation) {

            const relation = this.props.data.tmp_relation.features.find(r => {
                return r.properties.id.split('/')[0] === 'relation';
            })

            if (relation) {

                const dom = [];
                for (let key in relation.properties) {

                    dom.push(<div key={`r_${key}`} style={{ display: 'flex', fontSize: 12, color: '#999', position: 'relative', borderBottom: '1px solid #313131' }}>
                        <div>{key}</div>
                        <div style={{ position: 'absolute', left: 180, }}>{relation.properties[key]}</div>
                    </div>)
                }

                return dom;
            }
        }

        return null;
    }

    renderStations() {
        if (this.props.data && this.props.data.tmp_relation) {

            const stations = this.props.data.tmp_relation.features.filter(feature => { return feature.properties.id.split('/')[0] === 'node' });
            const relation = this.props.data.tmp_relation.features.filter(feature => { return feature.properties.id.split('/')[0] === 'relation' });

            const probableStartStation = relation[0].properties.from;

            return stations.map(station => {

                if (probableStartStation === station.properties.name) {
                    return (
                        <option selected key={station.properties.name} value={station.properties.name}>{station.properties.name}</option>
                    )
                }
                return (
                    <option key={station.properties.name} value={station.properties.name}>{station.properties.name}</option>
                )
            })
        }

        return null;
    }

    render() {

        const relationInfoCss = {
            root: {
                position: 'absolute',
                top: 100,
                width: 330,
                height: 300,
                background: '#222',
                zIndex: 6,
                left: 8,
                padding: 8,
                overflow: 'auto',
            },
            button: {
                width: 110,
                hieght: 25,
                backgroundColor: 'rgb(36, 86, 182)',
                color: '#ccc',
                padding: '8px 12px',
                outline: 'none',
                border: 'none',
                fontSize: 12,
                position: 'absolute',
                right: 8,
                bottom: 12,
                cursor: 'pointer',
            },
            dropdown: {
                position: 'absolute',
                left: 8,
                bottom: 12,
                height: 30,
                outline: 'none',
                background: '#222',
                border: '1px solid #333',
                color: '#aaa',
            }
        }

        return (
            <div style={relationInfoCss.root}>
                {this.renderData()}
                <select style={relationInfoCss.dropdown} onChange={e => this.setState({ startStation: e.target.value })}>
                    {this.renderStations()}
                </select>
                <button onClick={() => this.props.onBuildRoute(this.props.data.tmp_relation.features.find(station => { return station.properties.name === this.state.startStation }))} style={relationInfoCss.button}>Build Route</button>
            </div>
        )
    }
}