import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { updateRoute, savePublicTransportEdges, savePublicTransportNodes, savePublicTransportNodeToStops, getRouting } from '../api/';

export default class PublicTransportActions extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleCommitExpandClick = () => {
        this.setState({
            show_code_snippet: true,
        })
    }

    handleCopyCodeClick = () => {
        navigator.clipboard.writeText(document.getElementById('codesnippet').value);
        this.setState({
            codeCopied: true,
            enableCommit: true,
        });

        setTimeout(() => {
            this.setState({
                codeCopied: false,
            })
        }, 400);
    }

    handleCloseCodeSnippet = () => {
        this.setState({
            show_code_snippet: false,
        })
    }

    handleTryRouting = () => {
        const rand1 = Math.random() * this.props.data.route_nodes.features.length;
        const rand2 = Math.random() * this.props.data.route_nodes.features.length;
        this.setState({
            tryRoutingPopup: true,
            hideInfoActions: true,
            tryRoutingStation1: this.props.data.route_nodes.features[Math.floor(rand1)].properties,
            tryRoutingStation2: this.props.data.route_nodes.features[Math.floor(rand2)].properties,

        })
    }

    handleRandomize = () => {
        this.setState({
            tryRoutingStation1: this.props.data.route_nodes.features[Math.floor(Math.random() * this.props.data.route_nodes.features.length)].properties,
            tryRoutingStation2: this.props.data.route_nodes.features[Math.floor(Math.random() * this.props.data.route_nodes.features.length)].properties,
        })
    }

    handleTryRoutingExample = async () => {
        const { tryRoutingStation1, tryRoutingStation2 } = this.state;
        const routing = (await getRouting({ source: tryRoutingStation1.nodeno || tryRoutingStation1.id.split('/')[1], target: tryRoutingStation2.nodeno || tryRoutingStation2.id.split('/')[1] })).data;
        this.props.onRouting(routing);
    }

    handleCommit = async () => {

        // prevent from dubleclicking button while storing data
        this.setState({
            enableCommit: false,
        })
        this.props.onUpdateNotification('Storing generated route as geojson');
        // store generate route as geojson to public_transport_routes table
        // set locked = true for this route
        const { relation } = this.props.data;
        await updateRoute({ ...relation, flagged: false, locked: true, geojson: JSON.stringify(this.props.data.route) });

        this.props.onUpdateNotification('Saving station → station structure in Database');
        // store station -> station structure in database (check for duplicates)
        const { route } = this.props.data;
        await savePublicTransportEdges(route);

        this.props.onUpdateNotification('Saving generated stations in Database');
        // store generated stations in database (check for duplicates)
        const { route_nodes } = this.props.data;
        await savePublicTransportNodes(route_nodes);

        this.props.onUpdateNotification('Populating node → stops structure');
        // populate node to stops table
        await savePublicTransportNodeToStops({ route_nodes, route, relation });

        // update opened UI (disable commit, enable try routing, show success message.)
        this.props.onUpdateNotification(null);

        this.setState({
            tryRoutingEnabled: true,
            enableCommit: false,
            showSuccessMessageInTooltip: true,
            show_code_snippet: false,
        })
    }

    render() {
        const paintedRouteForInspection = {
            type: 'FeatureCollection',
            features: this.props.data.route.features && this.props.data.route.features.map((feature, i) => {
                return { ...feature, properties: { ...feature.properties, stroke: i % 2 === 0 ? 'green' : 'red' } }
            }) || []
        }
        return (
            <div>
                {
                    this.state.tryRoutingPopup ? <div>
                        <div className="try-routing-popup-info">
                            <div style={{ position: 'absolute', left: 12, }}>{this.state.tryRoutingStation1.name}</div>
                            <div style={{ position: 'absolute', left: 174, }}>{this.state.tryRoutingStation2.name}</div>
                        </div>
                        <div className="try-routing-popup">
                            <input type="text" value={this.state.tryRoutingStation1.uic_ref} placeholder="uic ref 1" />
                            <input type="text" value={this.state.tryRoutingStation2.uic_ref} placeholder="uic ref 2" />
                            <Button onClick={this.handleTryRoutingExample}>Route</Button>
                            <Button onClick={this.handleRandomize}>Randomize</Button>
                        </div></div> : ''
                }
                {this.state.show_code_snippet ?
                    <div className="public-transport-actions-codesnippet">
                        <div><h5 style={{ fontSize: 16, margin: '4px 0 12px', }}>Inspect Code Snippet</h5><div style={{ cursor: 'pointer', color: 'rgb(153, 153, 153)', position: 'absolute', right: 2, top: 0, }}><CloseIcon onClick={this.handleCloseCodeSnippet} /></div></div>
                        <div style={{ marginBottom: 12, }}>We painted station → station segments in alternating colors. Copy this code and check if data is correct. use geojson.io tool to inspect this snippet.</div>
                        <div>
                            <div style={{ visibility: this.state.codeCopied ? 'visible' : 'hidden', backgroundColor: '#f1f1f1', color: '#1f1f1f', fontSize: 10, fontWeight: 300, color: 'rgb(31, 31, 31)', width: 50, height: 13, position: 'absolute', right: 20, borderRadius: 2, padding: 2, }}>Copied!</div>
                            <textarea readOnly id="codesnippet" value={JSON.stringify(paintedRouteForInspection)} />
                        </div>
                        <div style={{ display: 'flex', marginLeft: 12, }}>
                            <Button onClick={this.handleCopyCodeClick}>Copy Code</Button>
                        </div>
                    </div> : ''
                }

                <div className="public-transport-actions-tooltip" style={{ visibility: this.state.hideInfoActions ? 'hidden' : 'visible' }}>
                    {
                        this.state.showSuccessMessageInTooltip ? <div>Great, whole route has been properly stored! Thanks. You can safely click close button. Consider updating H3s if you didn't do that yet. Also Try out routing to make sure everyting works as expected.</div> : <div>
                            Please make sure generated route {this.props.data.relation.name} is correct before commiting.
                            Also check h3s at route stations, if you see some missing after commiting
                            click 'Update h3s'. You can test routing from station → station only after commiting route.
                            {this.state.enableCommit ? <Button onClick={this.handleCommit}>Commit</Button> : ''}
                        </div>
                    }
                </div>
                <div className="public-transport-actions">
                    <Button onClick={this.handleCommitExpandClick}>Commit...</Button>
                    <Button disabled={this.state.h3sUpdateDisabled} onClick={() => { this.setState({ h3sUpdateDisabled: true }); this.props.onH3sUpdate() }}>Update H3s</Button>
                    <Button onClick={this.handleTryRouting} disabled={!this.state.tryRoutingEnabled}>Try Routing</Button>
                    <Button onClick={() => this.props.onClose()} className="close">Close</Button>
                </div>
            </div>
        )
    }
}